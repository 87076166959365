<section class="mx-3">
  <app-toast></app-toast>
  <mat-dialog-content class="mat-typography" style="overflow-y: auto; max-height: 80vh">
    <div class="d-flex align-items-center mb-3">
      <h2 class="m-0">Add Subject</h2>
    </div>
    <ng-template *ngIf="errorMessage">
      <small class="text_color_red">{{ errorMessage }}</small>
    </ng-template>
    <form [formGroup]="form">
      <div class="row mb-3">
        <div class="col-md-4 col-sm-6 d-flex flex-column align-items-start">
          <input
            #fileInput
            hidden="true"
            type="file"
            color="primary"
            (change)="imageInputChange($event)"
          />
          <button mat-raised-button color="primary" class="image_choose_button" (click)="fileInput.click()">
            Choose File
          </button>
         
          <p *ngIf="fileName" class="mt-2">Selected file: {{ fileName }}</p>
          <p *ngIf="!fileName"  class="text-start mt-1">Upload Subject Image</p>
        </div>
        <div class="col-md-2 col-sm-6 d-flex align-items-center mt-3 mt-md-0">
          <div>
            <img
              *ngIf="selectedImageUrl"
              [src]="selectedImageUrl"
              alt="Selected Image"
              (click)="fileInput.click()"
              style="width: 4.5rem; height: 4.5rem; cursor: pointer;"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div *ngFor="let question of questions" class="mb-3 col-sm-6">
          <app-question [question]="question" [form]="form"></app-question>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div class="form-row">
      <button mat-raised-button (click)="closeDialog()" class="px-5 cancel_button" >Cancel</button>
      <button *ngIf="!editflag" mat-raised-button color="primary" class="px-5 submit_button" (click)="onSubmit(editflag)" type="submit" [disabled]="!form.valid">Submit</button>
      <button *ngIf="editflag" mat-raised-button color="primary" class="px-5 update_button" (click)="onSubmit(editflag)" type="submit" [disabled]="!form.valid">Update</button>
    </div>
  </mat-dialog-actions>
</section>
