import { Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogModule,
} from '@angular/material/dialog';
import { AddbannersComponent } from './addbanners/addbanners.component';
import { BannerDetailDialog } from './banner-detail-dialog/banner-detail-dialog.component';
import { FrontpageService } from '../../../../inputfileds/form_services/collegemanagement/frontpage.service';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';

export interface PeriodicElement {
  index: number;
  id: string;
  name: number;
  status: number;
  tillDate: string;
}

@Component({
  selector: 'app-banners',
  standalone: true,
  imports: [
    MatIcon,
    MatCardModule,
    MatTableModule,
    MatButtonModule,
    MatDialogModule,
    CommonModule,
    MatMenuModule,
  ],
  templateUrl: './banners.component.html',
  styleUrl: './banners.component.scss',
})
export class BannersComponent {
  displayedColumns: string[] = [
    'fromDate',
    'toDate',
    'info',
    'status',
    'images',
    'actions',
  ];
  bannersData: any = [];
  formattedDates: any;
  editId: string | null = null;
  imagePath = this.apiservice.environmentPath;

  constructor(
    private ApiService: ApiservicesService,
    private dialog: MatDialog,
    private bannersService: FrontpageService,
    private apiservice: ApiservicesService,
  ) {
    
  }

  ngOnInit() {
    this.getBannersData();
  }
  openUploadDialog(): void {
    const dialogConfig = this.configureDialog({
      data: null,
      minHeight: '65vh',
      maxHeight: '65vh',
    });
  
    const dialogRef = this.dialog.open(AddbannersComponent, dialogConfig);
  
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getBannersData(); // Refresh banner list after success
      }
    });
  }

    viewItem(element: any): void {
      const dialogConfig = this.configureDialog({
        data: {
          banner: element, // Pass the selected banner data
          editCallback: (banner: any) => this.editItem(banner),
          deleteCallback: (id: number) => this.deleteApi(id),
        },
        minHeight: '40vh',
        maxHeight: '80vh',
      });
    
      const dialogRef = this.dialog.open(BannerDetailDialog, dialogConfig);
    
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          this.getBannersData();
        }
      });
    }
    
  
    editItem(element: any): void {
      const dialogConfig = this.configureDialog({
        data: element,
        minHeight: '65vh',
        maxHeight: '65vh',
      });
      const dialogRef = this.dialog.open(AddbannersComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          this.getBannersData();
        }
      });
    }

    deleteApi(id: number) {
      const confirmDelete = confirm('Are you sure you want to delete this banner?');
      if (confirmDelete) {
        this.apiservice.deleteBanners(id).subscribe(
          (res: any) => {
            if (res.code === 200 && res.status === true) {
              console.log('Banner deleted successfully:', res.message);
              this.getBannersData();
            } else {
              console.error('Error deleting banner:', res.message || 'Unknown error');
            }
          },
          (error) => {
            console.error('API Error during delete:', error);
          }
        );
      }
    }

  updateItem(row: any) {
    this.ApiService.updateBanners(row.id, row).subscribe(
      () => {
        console.log('Banner updated successfully');
        this.editId = null;
        this.getBannersData();
      },
      (error) => {
        console.error('Error updating banner:', error);
      }
    );
  }
  getBannersData() {
    this.ApiService.getBannerslist().subscribe(
      (data: any) => {
        this.bannersData = data.rows;
      },
      (error) => {
        console.error('Error fetching banners data:', error);
      }
    );
  }

  private configureDialog(config: Partial<MatDialogConfig>): MatDialogConfig {
    return {
      disableClose: true,
      panelClass: ['dialog-bottom-enter', 'dialog-bottom-leave', 'animate__animated'],
      maxWidth: '50vw',
      ...config,
    };
  }
}
