import { Injectable } from '@angular/core';
import { Inputfiledsbase } from '../../models/inputfiledsbase';
import { DropdownQuestion } from '../../question-dropdown';
import { TextboxQuestion } from '../../question-textbox';
import { of, Observable } from 'rxjs';
import { FileuploadQuestion } from '../../question-fileuplaod';
import { DatepickerQuestion } from '../../question-datepicker';
import { TextareaQuestion } from '../../question-textarea';

@Injectable({
  providedIn: 'root',
})
export class FrontpageService {
  constructor() {}

  getBannersForm(): Observable<Inputfiledsbase<string>[]> {
    const questions: Inputfiledsbase<string>[] = [
      new TextboxQuestion({
        key: 'image_name',
        label: 'Image Name',
        required: true,
        order: 1,
      }),
      new DatepickerQuestion({
        key: 'start_date',
        label: 'Start Date',
        required: true,
        order: 2
      }),
      new DatepickerQuestion({
        key: 'end_date',
        label: 'End Date',
        required: true,
        order: 3,
      }),
    ];
    return of(questions);
  }

  getNoticeForm(): Observable<Inputfiledsbase<string>[]> {
    const questions: Inputfiledsbase<string>[] = [
      new TextboxQuestion({
        key: 'notice_heading',
        label: 'Notice Heading',
        required: true,
        order: 1,
      }),
      new TextareaQuestion({
        key: 'description',
        label: 'Description',
        required: true,
        order:2,
      }),
      // new TextboxQuestion({
      //   key: 'button_text',
      //   label: 'Button Text',
      //   required: true,
      //   order: 3,
      // }),
      // new TextboxQuestion({
      //   key: 'button_link',
      //   label: 'Button Link',
      //   required: true,
      //   order: 4,
      // }),
      new DatepickerQuestion({
        key: 'start_date',
        label: 'Start Date',
        required: true,
        order: 5
      }),
      new DatepickerQuestion({
        key: 'end_date',
        label: 'End Date',
        required: true,
        order: 6,
      }),
    ];
    return of(questions);
  }
  getMissionVissionForm(): Observable<Inputfiledsbase<string>[]> {
    const questions: Inputfiledsbase<string>[] = [
      new TextareaQuestion({
        key: 'description',
        label: 'Description',
        required: true,
        order:1,
      })
    ];
    return of(questions);
  }

  getTestimonialForm(): Observable<Inputfiledsbase<string>[]> {
    const questions: Inputfiledsbase<string>[] = [
      new TextboxQuestion({
        key: 'heading',
        label: 'Heading',
        required: true,
        order: 1,
      }),

      new TextareaQuestion({
        key: 'description',
        label: 'Description',
        required: true,
        order: 2,
      })
    ];
    return of(questions);
  }

}
