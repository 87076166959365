<div class="container">
  <app-toast></app-toast>
  <div class="d-flex align-items-center">
    <div>
      <button mat-icon-button class="back_icon me-2"
        [routerLink]="['/lookupcodeview']"><mat-icon>arrow_back</mat-icon></button>
    </div>
    <div>
      <h4 class="m-0">Add Lookup Code</h4>
    </div>
  </div>

  <div class="bg-white p-3 mt-2">
    <div *ngIf="errormessage">
      <small class="text_color_red">{{errormessage}}</small>
    </div>
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="row">
        <div *ngFor="let question of questions" class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
          <app-question [question]="question" [form]="form"></app-question>
        </div>
      </div>
      <div class="form-row">
        <button mat-raised-button color="primary" class="px-5 submit_button" type="submit" [disabled]="!form.valid">Save</button>
      </div>
    </form>
  </div>
  <!-- <div *ngIf="payLoad" class="form-row">
      <strong>Saved the following values</strong><br>{{payLoad}}
    </div> -->
</div>