<section class="mx-3">
  <div class="content-header">
    <h1>Home Page - Testimonials</h1>
    <button
      mat-raised-button
      color="primary"
      class="upload-btn add_button"
      (click)="openUploadDialog()"
    >
      + Upload Testimonials
    </button>
  </div>

  <table mat-table [dataSource]="testimonialsData" class="mat-elevation-z8">
    <ng-container matColumnDef="uploadDate">
      <th mat-header-cell *matHeaderCellDef>Upload Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.created_at | date : "dd/MM/yyyy" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td
        mat-cell
        *matCellDef="let element"
        matTooltip="{{ element.description }}"
        class="cursor_pointer"
        matTooltipPosition="below"
      >
        {{ element.description | slice : 0 : 10
        }}{{ element.description.length > 10 ? "..." : "" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="images">
      <th mat-header-cell *matHeaderCellDef>Video</th>
      <td mat-cell *matCellDef="let element">
        <div class="video-thumbnail-container">
          <video
          #videoPlayer
            width="100%"
            height="auto"
            controls
            [poster]="
              'https://dbraeiassetmanagement.blob.core.windows.net/dbradcdev/' +
              element.media_url +
              '?alt=media&token=thumbnail_token'
            "
            class="testimonials-video"
            muted
            preload="metadata"
            (play)="viewItem(element, videoPlayer)"
          >
            <source
              [src]="
                'https://dbraeiassetmanagement.blob.core.windows.net/dbradcdev/' +
                element.media_url
              "
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <div class="play-button-overlay" (click)="viewItem(element)">
            <mat-icon>play_circle_filled</mat-icon>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <button mat-button class="view_button" (click)="viewItem(element)">
          View
        </button>
        <button
          class="action_button"
          mat-icon-button
          [matMenuTriggerFor]="menu"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            class="update_button"
            *ngIf="editId !== element.id"
            (click)="editItem(element)"
          >
            <mat-icon>edit</mat-icon>
            Edit
          </button>
          <button
            mat-menu-item
            class="delete_button"
            (click)="deleteItem(element.id)"
          >
            <mat-icon>delete</mat-icon>
            Delete
          </button>
        </mat-menu>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</section>
