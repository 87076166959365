import { RouterModule, Routes } from '@angular/router';
import { LookupcodesComponent } from './pages/lookupcodes/lookupcodes.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LayoutComponent } from './layout/layout.component';
import { LookuptypesComponent } from './pages/lookuptypes/lookuptypes.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { DepartmentComponent } from './pages/department/department.component';
import { StudentComponent } from './pages/student/student.component';
import { EmployeeComponent } from './pages/employee/employee.component';
import { AuthGuard } from './components/Authentication/auth.guard';
import { LoginComponent } from './components/login/login.component';
import { BannersComponent } from './components/websitemenagement/Homepage/banners/banners.component';
import { NoticeBoardComponent } from './components/websitemenagement/Homepage/notice-board/notice-board.component';
import { TestimonialsComponent } from './components/websitemenagement/Homepage/testimonials/testimonials.component';
import { VisionMissionComponent } from './components/websitemenagement/Homepage/vision-mission/vision-mission.component';
import { LookupcodesviewComponent } from './lookupcodes/lookupcodesview/lookupcodesview.component';
import {
  EmployeeAtendanceModule,
  EmployeeatendanceComponent,
} from './pages/attendance/employeeatendance/employeeatendance.component';
import { StudentprofileviewComponent } from './profile/studentprofileview/studentprofileview.component';
import { CoursesComponent } from './pages/courses/courses.component';
import { StreamComponent } from './pages/stream/stream.component';
import { HomepageviewComponent} from './pages/website-pages/homepageview/homepageview.component';
import { NgModule } from '@angular/core';
import { EmployeeprofileviewComponent } from './profile/employeeprofileview/employeeprofileview.component';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { ToastComponent } from './components/toast/toast.component';
import { StudentattendanceComponent } from './pages/attendance/studentattendance/studentattendance.component';
import { WorkingdaysComponent } from './pages/settings/workingdays/workingdays.component';
import { LeavetypesComponent } from './pages/attendance/leaveutilities/leavetypes/leavetypes.component';
import { LeaverequestComponent } from './pages/attendance/leaveutilities/leaverequest/leaverequest.component';
import { LeaverequestviewComponent } from './pages/attendance/leaveutilities/leaverequestview/leaverequestview.component';
import { LeaveviewsComponent } from './pages/attendance/leaveutilities/leaveviews/leaveviews.component';
import { AddorganizationComponent } from './pages/organization/organization.component';
import { CollegedetailsComponent } from './pages/organization/collegedetails/collegedetails.component';
import { LeavesassignedComponent } from './pages/leavesassigned/leavesassigned/leavesassigned.component';
import { SemestersComponent } from './pages/courses/semesters/semesters.component';
import { SectionsComponent } from './pages/academics/academicregulations/sections/sections.component';
import { AcademicregulationsComponent } from './pages/academics/academicregulations/academicregulations.component';
import { AcademicyearsComponent } from './pages/academics/academicyears/academicyears.component';
import { LoggerComponent } from './pages/logger/logger.component';
import { AcademicyearsdetailsComponent } from './pages/academics/academicyears/academicyearsdetails/academicyearsdetails.component';
import { AcademicregulationsviewComponent } from './pages/academics/academicregulations/academicregulationsview/academicregulationsview.component';
import { TimetableComponent } from './pages/timetable/timetable.component';
import { AddtimetablesComponent } from './pages/timetable/timetables/addtimetables/addtimetables.component';
import { AddTimeslotComponent } from './pages/timetable/timeslot/addtimeslot/addtimeslot.component';
import { ClassesComponent } from './pages/classes/classes.component';
import { ClassesviewComponent } from './pages/classes/classesview/classesview.component';
import { ViewtimetableComponent } from './pages/timetable/viewtimetable/viewtimetable.component';
import { CreatePermissionGuard } from './components/Authentication/permissionguard/add-permission.guard';
import { ViewPermissionGuard } from './components/Authentication/permissionguard/view-permission.guard';
import { ActivitiesPageViewComponent } from './pages/website-pages/activitiespageview/activitiespageview.component';
import { ActivityOverviewComponent } from './pages/website-pages/activitiespageview/activity-overview-component/activity-overview-component.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    // canActivate: [AuthGuard],
  },
  {
    // path: 'login/:role',
    path: 'login',
    pathMatch:'full',
    component: LoginComponent
  },
  {
    path: 'loginfp',
    component: ForgotpasswordComponent
  },
  {
    path: 'toast',
    component: ToastComponent
  },
  {
    path: 'layout',
    component: LayoutComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'lookupcodeview',
    component: LookupcodesviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'department',
    component: DepartmentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'lookupcode',
    component: LookupcodesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'lookuptype',
    component: LookuptypesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'student',
    component: StudentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'studentview/:dataId',
    pathMatch:'full',
    component: StudentprofileviewComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'studentview',
    pathMatch:'full',
    component: StudentprofileviewComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'employeeview/:dataId',
    pathMatch:'full',
    component: EmployeeprofileviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'employeeview',
    pathMatch:'full',
    component: EmployeeprofileviewComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'employeeatendance',
    component: EmployeeatendanceComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'studentatendance',
    component: StudentattendanceComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'organizations',
    component: AddorganizationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'collegedetails',
    component: CollegedetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'timings',
    component: WorkingdaysComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'homepage',
    component: HomepageviewComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'activities',
    component: ActivitiesPageViewComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'activities/:id',
    component: ActivityOverviewComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'employee',
    component: EmployeeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'courses',
    // component: CoursesComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: CoursesComponent,
        pathMatch: 'full',
      },
      {
        path: 'semesterdetails',
        component: SemestersComponent,
        canActivate: [ViewPermissionGuard],
      },
    ],
  },
  {
    path: 'stream',
    component: StreamComponent,
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'semesterdetails',
  //   component: SemestersComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'leavetype',
    component: LeavetypesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'assignedleaves',
    component: LeavesassignedComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'requests',
    component: LeaverequestComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'requestsview',
    component: LeaverequestviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'academicregulations',
    // component: AcademicregulationsComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: AcademicregulationsComponent,
        pathMatch: 'full',
      },
      {
        path: 'academicregulationsview',
        component: AcademicregulationsviewComponent,
        canActivate: [ViewPermissionGuard],
      },
    ]
  },
  {
    path: 'sectiondetails',
    component: SectionsComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'academicregulationsview',
  //   component: AcademicregulationsviewComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'academicyears',
    // component: AcademicyearsComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: AcademicyearsComponent,
        pathMatch: 'full',
      },
      {
        path: 'academicyearsdetails',
        component: AcademicyearsdetailsComponent,
        canActivate: [ViewPermissionGuard],
      },
    ]
  },
  // {
  //   path: 'academicyearsdetails',
  //   component: AcademicyearsdetailsComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'timetable',
    // component: TimetableComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: TimetableComponent,
        pathMatch: 'full',
      },
      {
        path: 'timetableview',
        component: ViewtimetableComponent,
        pathMatch: 'full', 
        canActivate: [ViewPermissionGuard]

      },
      {
        path: 'addtimetable',
        pathMatch: 'full',
        component: AddtimetablesComponent,
        canActivate: [CreatePermissionGuard]
      },
    ],
  },
  // {
  //   path: 'timetableview',
  //   component: ViewtimetableComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'addtimeslots',
    component: AddTimeslotComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'addtimetable',
  //   component: AddtimetablesComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'leaveview',
    component: LeaveviewsComponent,
    canActivate: [AuthGuard],
  },
  {
    path:'classes',
    // component: ClassesComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ClassesComponent,
        pathMatch: 'full',
      },
      { 
        path:'classview',
        component: ClassesviewComponent,
        // canActivate: [AuthGuard],
      }
    ],
  },
  {
    path: 'logger',
    component: LoggerComponent,
    canActivate: [AuthGuard]
  },
  { path: '**', component: DashboardComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
