import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-testi-monials-detail-dialog',
  imports: [
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    CommonModule,
  ],
  standalone: true,
  templateUrl: './testi-monials-detail-dialog.component.html',
  styleUrls: ['./testi-monials-detail-dialog.component.scss'],
})
export class TestiMonialsDetailDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TestiMonialsDetailDialogComponent>
  ) {
    console.log('Dialog data:', this.data);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  editTestimonials(): void {
    if (this.data.editCallback) {
      this.data.editCallback(this.data.testimonials);
    }
    this.dialogRef.close();
  }

  deleteTestimonials(): void {
    if (this.data.deleteCallback) {
      this.data.deleteCallback(this.data.testimonials.id);
    }
    this.dialogRef.close();
  }
}
