<div class="header_fixed">
<mat-toolbar class="background-header-color">
  <img src="../../../assets/logo/logo.png"/>
    <span class="title" routerLink="/"></span>
    <button class="action_button" (click)="menuToggled.emit(true)" mat-icon-button>
      <mat-icon>menu</mat-icon>
    </button>
    <span class="spacer"></span>
    <span class="welcome-text">{{ user }}</span>
    <button mat-icon-button class="action_button" [matMenuTriggerFor]="menu">
      <mat-icon>person_pin</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item  class="action_button" (click)="gotoprofile()">
        <span>Profile</span>
      </button>
      <button mat-menu-item  class="action_button" (click)="logout()">
        <span>Logout</span>
      </button>
    </mat-menu>
  </mat-toolbar>
</div>