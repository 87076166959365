<div class="p-3">
    <div class="row">
        <div class="col-sm-3 col-md-3">
            <div>
                <div class="card">
                    <div class="position-relative">
                        <div class="position-absolute top-0 end-0">
                            <div class="m-2">
                                <input #csvInput hidden="true" type="file" onclick="this.value=null"
                                    (change)="csvInputChange($event)"  />
                                <!-- <button mat-flat-button color="primary" (click)="csvInput.click()">Choose Spreadsheet
                                    File (CSV)</button> -->
                                <mat-icon aria-hidden="false" aria-label="Example home icon"
                                    [ngStyle]="{'color':'#3f51b5'}" fontIcon="create" (click)="csvInput.click()"></mat-icon>
                            </div>
                        </div>
                        <ng-container *ngIf="updateImgflag">
                            <div class="position-absolute top-50 start-50 translate-middle">
                                <button mat-flat-button class="update_button" color="primary">update</button>
                            </div>
                        </ng-container>
                        <img #img [src]='imageUrl' (error)="img.src = onerror" class="card-img-top"
                            alt="...">
                        <div class="card-body bg-profile-blue">
                            <div class="position-relative">
                                <div class="card-text text-center text-white">
                                    <div>
                                        <h4 class="m-0"><strong>{{studentData?.first_name}}
                                                {{studentData?.last_name}}</strong></h4>
                                    </div>
                                    <div>
                                        <p class="m-0">Course: {{studentData?.course_name}}</p>
                                    </div>
                                    <div>
                                        <p class="m-0">Roll.No: {{studentprofile?.roll_number}}</p>
                                    </div>
                                    <div>
                                        <p class="m-0">Semister: {{studentprofile?.semister}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-9 col-md-9">
            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
                <mat-tab label="Personal">
                    <div class="mt-4">
                        <div class="row m-0">
                            <div class="col-md-6">
                                <div>
                                    <p><strong> Hallticket Number : </strong><span>{{studentprofile?.hallTicket_number}}</span></p>
                                </div>
                                <div>
                                    <p><strong> Gender : </strong><span>{{studentprofile?.gender}}</span></p>
                                </div>
                                <div>
                                    <p><strong> Date of Birth: </strong><span>{{studentprofile?.date_of_birth}}</span>
                                    </p>
                                </div>
                                <div>
                                    <p><strong> Aadhar : </strong><span>{{studentprofile?.adhar_number}}</span></p>
                                </div>
                                <div>
                                    <p><strong> Contact : </strong><span>{{studentData?.phone_number}}</span></p>
                                </div>
                                <div>
                                    <p><strong> Email Id : </strong><span>{{studentData?.email}}</span></p>
                                </div>
                                <div>
                                    <p><strong> Caste : </strong><span>{{studentData?.personal_details.caste}}</span></p>
                                </div>
                                <div>
                                    <p><strong> SubCaste : </strong><span>{{studentData?.personal_details.sub_caste}}</span></p>
                                </div>
                                <div>
                                    <p><strong> Present Address : </strong><span *ngIf="studentaddress.length>0">{{studentaddress[0]?.address_line1}},{{studentaddress[0]?.address_line2}}, {{studentaddress[0]?.city}}, {{studentaddress[0]?.state_name}}, {{studentaddress[0]?.country_name}}, {{studentaddress[0]?.pincode}}</span></p>
                                </div>
                                <div>
                                    <p><strong> Permanent Address : </strong><span *ngIf="studentaddress.length>0">{{studentaddress[1]?.address_line1}},{{studentaddress[1]?.address_line2}}, {{studentaddress[1]?.city}}, {{studentaddress[1]?.state_name}}, {{studentaddress[1]?.country_name}}, {{studentaddress[1]?.pincode}}</span></p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div>
                                    <p><strong> Future Goal : </strong><span>{{studentprofile?.future_goal}}</span></p>
                                </div>
                                <div>
                                    <p><strong> Extra Curricular Activities :
                                        </strong><span>{{studentprofile?.interested_extra_activities}}</span></p>
                                </div>
                                <div>
                                    <p><strong> Participation in NCC/NSS :
                                        </strong><span>{{studentprofile?.participation_in_ncc_nss}}</span></p>
                                </div>
                                <div>
                                    <p><strong> Participation in Sports/Games :
                                        </strong><span>{{studentprofile?.participation_in_sports_games}}</span></p>
                                </div>
                                <div>
                                    <p><strong> Hobbies : </strong><span>{{studentprofile?.hobbies}}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Education">
                    <div class="mt-4">
                        <div class="row m-0" *ngIf="studenteducation.length > 0; else nodata">
                            <div class="col-md-6 mb-3" *ngFor="let education of studenteducation">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">{{education?.qualification}}</h5>
                                        <div class="card-text">
                                            <div>
                                                <p><strong>Inter Stream :
                                                    </strong><span>{{education?.course_name}}</span></p>
                                            </div>
                                            <div>
                                                <p><strong>Marks : </strong><span>{{education?.percentage}}</span></p>
                                            </div>
                                            <div>
                                                <p><strong>Year of Pass : </strong><span>{{education?.end_year | date: 'dd/MM/yyyy'}}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-template #nodata>
                            <h4>No Data</h4>
                        </ng-template>
                    </div>
                </mat-tab>
                <mat-tab label="Family">
                    <div class="mt-4">
                        <div class="row m-0">
                            <div class="col-md-12">
                                <p><strong>Father’s Name : </strong><span>{{studentfamily?.fathers_name}}</span></p>
                            </div>
                            <div class="col-md-12">
                                <p><strong> Father's Occupation :
                                    </strong><span>{{studentfamily?.fathers_occupation}}</span></p>
                            </div>
                            <div class="col-md-12">
                                <p><strong> Mother's Name : </strong><span>{{studentfamily?.mothers_name}}</span></p>
                            </div>
                            <div class="col-md-12">
                                <p><strong> Mother's Occupation :
                                    </strong><span>{{studentfamily?.mothers_occupation}}</span></p>
                            </div>
                            <div class="mt-5">                                
                                <div class="col-md-12">
                                    <p><strong> No. of Brothers :
                                        </strong><span>{{studentfamily?.number_of_brothers}}</span></p>
                                </div>
                                <div class="col-md-12">
                                    <p><strong> No. of sisters :
                                        </strong><span>{{studentfamily?.number_of_sisters}}</span></p>
                                </div>
                            <div class="col-md-12">
                                <p><strong> Annual Income : </strong><span>{{studentfamily?.annual_income}}</span></p>
                            </div>
                            <div class="col-md-12">
                                <p><strong> Parents Contact Number :
                                    </strong><span>{{studentfamily?.parent_contact_number}}</span></p>
                            </div>
                        </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>