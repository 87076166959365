<div class="dialog-content">  
    <div class="notice-container">
      <img
      [src]="'https://dbraeiassetmanagement.blob.core.windows.net/dbradcdev/' + data.notice.image_url"
        alt="Notice Image"
        class="notice-image"
      />
    </div>
  
    <div class="notice-details">
      <h2 class="notice-title">{{ data.notice.heading }}</h2>
      <p class="notice-description">{{ data.notice.description }}</p>
      <div class="notice-status">
        <p><strong>Active From:</strong> {{ data.notice.visible_from | date: 'dd/MM/yyyy' }}</p>
        <p><strong>Active Till:</strong> {{ data.notice.visible_until | date: 'dd/MM/yyyy' }} </p>
         <p><strong>Status:</strong> <span
            [ngClass]="{
              'status-active': data.status === 'Active',
              'status-inactive': data.status !== 'Active'
            }"
          >
            {{ data.status === 'Active' ? 'Still Active' : 'Inactive' }}
          </span>
       </p>
      </div>
    </div>
  
    <div class="dialog-actions">
      <button mat-button class="cancel_button" (click)="closeDialog()">Cancel</button>
      <button mat-raised-button color="primary" (click)="editNotice()">Edit</button>
      <button mat-raised-button color="warn" 
      (click)="deleteNotice()"
      >Delete</button>
    </div>
  </div>
  