<section class="container">
  <h2 class="text-start m-4">Add Testimonials</h2>
  <mat-divider></mat-divider>
  
  <div class="d-flex justify-content-between align-items-start mt-4">
      <form [formGroup]="testimonialsdata" class="flex-grow-1 me-4">
          <div *ngFor="let question of questions" class="col-10 mb-3">
              <div class="form-group">
                  <app-question
                      [question]="question"
                      [form]="testimonialsdata"
                  ></app-question>
              </div>
          </div>
      </form>

      <div class="selected-video-container">
          <div class="col-md-12 d-flex flex-column">
              <input type="file" accept="video/*" (change)="videoInputChange($event)" #fileInput hidden />
              <button
                  *ngIf="!selectedVideoUrl"
                  mat-raised-button
                  color="primary"
                  class="video_choose_button"
                  (click)="fileInput.click()"
              >
                  Choose Video
              </button>
          </div>

          <div *ngIf="selectedVideoUrl" class="video-preview" (click)="fileInput.click()">
              <video [src]="selectedVideoUrl" controls class="selected-video"></video>
          </div>
      </div>      
  </div>

  <mat-dialog-actions align="center" class="bg-white bottom-0 end-0">
      <div class="d-flex gap-2">
          <button
              mat-raised-button
              (click)="closeDialog(false)"
              class="px-5 cancel_button"
              color="warn"
          >
              Cancel
          </button>
          <button
              mat-raised-button
              color="primary"
              class="px-5 submit_button"
              type="submit"
              (click)="onSubmit()"
              [disabled]="!testimonialsdata.valid"
          >
              Submit
          </button>
      </div>
  </mat-dialog-actions>
</section>
