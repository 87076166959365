import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map} from 'rxjs/operators';
import { environment } from '../../../../environments/environment.prod';
import { ToastService } from '../../toast/toast.service';
import { AuthenticationService } from '../../../components/Authentication/services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class DropdownservicesService {

  private userdata: any;
  private userdatta: any;
  public demodata: Observable<Object> | undefined;
  private header: any;
  private fileheader: any;

  constructor(
    private http: HttpClient, 
    private toast: ToastService, 
    private AuthenticationService: AuthenticationService
  ) { 
    if (typeof sessionStorage !== 'undefined') {
      this.userdata = JSON.parse(`${typeof sessionStorage !== 'undefined' ? sessionStorage.getItem('user') : undefined}`);
      this.userdatta = this.userdata;
      this.header = {
        'token': this.userdatta?.data.token,
        'content-type': 'application/json'
      };
      this.fileheader = {
        'token': this.userdatta?.data.token
      };
    } else {
      console.warn('sessionStorage is not available in this environment');
      this.userdata = null;
      this.userdatta = null;
      this.header = null;
    }
  }

  getorganizationdrop() {
    return this.http.get(`${environment.API_URL}` + 'organizations?type=drop', { headers: { 'token': this.userdatta?.data.token } }).pipe(catchError(this.handleError))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getbatchesdropdown() {
    return this.http.get(`${environment.API_URL}` + 'academicregulations?type=drop&limit=100', { headers: { 'token': this.userdatta?.data.token } }).pipe(catchError(this.handleError))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getuserroles(data: any) {
    return this.http.get(`${environment.API_URL}` + 'userroles?type=drop', { headers: { 'token': this.userdatta?.data.token } }).pipe(catchError(this.handleError))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getstudentuserroles(data: any) {
    return this.http.get(`${environment.API_URL}` + 'userroles?type=drop&roleType=STUDENTS', { headers: { 'token': this.userdatta?.data.token } }).pipe(catchError(this.handleError))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getuserroledropdown() {
    return this.http.get<any>(`${environment.API_URL}` + 'userroles?type=drop&organizationId=' + this.userdatta?.data.organization_id, { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getlookupcodesdropdown(data: any) {
    console.log(data);
    return this.http.get<any>(`${environment.API_URL}` + 'lookupcodes?type=drop&lookupType=' + data, { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }
  
  getlooktypelist() {
    return this.http.get<any>(`${environment.API_URL}` + 'lookuptypes?type=drop&limit=1000', { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  // getdepartmentlist() {
  //   return this.http.get<any>(`${environment.API_URL}` + 'employees?type=drop', { headers: { 'token': this.userdatta?.data.token } }).pipe(
  //     map(data => {
  //       return data
  //     }),
  //     catchError(this.handleError))
  // }

  getemployeedrop() {
    return this.http.get(`${environment.API_URL}` + 'employees?type=drop&limit=1000', { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getcoursewisesemester(eventdata: any): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'semisters?type=drop&limit=100&course_id=' + eventdata.course_ID, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }
  
  getEmployeedrop(event:any) {
    return this.http.get<any>(`${environment.API_URL}` + 'employees?type=drop&organizationId=1&departmentId='+ event.department_id, { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getdepartmentdrop(){
    return this.http.get<any>(`${environment.API_URL}` + 'departments?type=drop', { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getassignleavedrop() {
    return this.http.get<any>(`${environment.API_URL}` + 'leavetypes?type=drop', { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  gettimeslotslist() {
    return this.http.get<any>(`${environment.API_URL}` + 'timeslots?type=drop&limit=100', { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getstreamdropdown() {
    return this.http.get(`${environment.API_URL}` + 'streams?type=drop&limit=1000', { headers: this.header }).pipe(
      map((data: any) => {
        return data
      }),
      catchError(this.handleError))
  }

  getcoursesdetails(): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'courses?type=drop', { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getcoursesbybatch(batchId: any): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'courses?type=drop&academic_regulation_id=' + batchId, { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getacademicregulationdrop(): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'academicregulations?type=drop&limit=1000', { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getacademicyeardrop(): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'academiccalendar?type=drop', { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getcoursewiseacademicyeardrop(event:any): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'academiccalendar?type=drop&course_id='+ event.course_id + '&academic_regulation_id='+ event.academic_regulation_id, { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getshiftdrop(): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'breaktimes?type=drop', { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }
  
  getshiftdropbyaccademic(BatchId:any): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'breaktimes?type=drop&limit=100&academic_regulation_id='+ BatchId, { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getsemisterdrop(): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'semisters?type=drop&limit=100', { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        console.log("lkjhgfdfghj", data)
        return data
      }),
      catchError(this.handleError))
  }

  getsectionsdrop(): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'organizationacademicsections?type=drop&limit=100', { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  gettablesectionsdrop(event:any): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'organizationwisesections?type=drop&course_id='+event.course_ID +'&academic_year_id='+event.academic_year_id+'&academic_regulation_id='+ event.academic_regulation_id, { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getsectionsdropdown(): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'sections?type=drop&organization_id=1&limit=100', { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getsectiondrop(eventdata: any): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'academicsections?type=drop&course_id='+ eventdata.course_ID, { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getcoursewisesemesterdrop(eventdata: any): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'academicyearsemisters?type=drop&limit=100&course_id=' + eventdata, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getstudentcoursewisesemesterdrop(eventdata: any): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'students?type=create&limit=100&course_id=' + eventdata, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getwebpagedrop() {
    return this.http.get(`${environment.API_URL}` + 'webpages?type=drop', { headers: this.header }).pipe(
      map(data => {
        return data

      }),
      catchError(this.handleError))
  }
  
  // Handle Error for Apis
  handleError(errorResponse: HttpErrorResponse) {
    console.log("errorResponse", errorResponse);
    if (errorResponse.status === 401) {
      sessionStorage.removeItem("user");
      window.location.reload();
      this.AuthenticationService.logout();
    }
    return throwError(errorResponse.message || 'Server error');
  }

  private dismissError(): void {
    setTimeout(() => {
      this.toast.dismissToast();
    }, 3000);
  }
}
