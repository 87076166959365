<!-- Main Component Template -->
<section class="mx-3">
  <div class="d-flex justify-content-between align-items-center  mb-2">
    <h3 class="m-0">Sections List</h3>
   <ng-container *ngIf="actiondata.create">
    <button
      mat-raised-button
      color="primary"
      (click)="openCreateSectionDialog()"
      class="px-4 add_button"
    >
      + Add Section
    </button>
  </ng-container>
  </div>
  <div class="mt-2 table-container">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- Section Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="header-cell">Section Name</th>
          <td mat-cell *matCellDef="let element" class="body-cell">{{ element.name }}</td>
        </ng-container>

        <!-- Sequence Column -->
        <ng-container matColumnDef="sequence">
          <th mat-header-cell *matHeaderCellDef class="header-cell">Sequence</th>
          <td mat-cell *matCellDef="let element" class="body-cell">{{ element.sequence }}</td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="header-cell">Actions</th>
          <td mat-cell *matCellDef="let row" class="body-cell">
            <button mat-raised-button  class="view_button" color="primary">View</button>
          </td>
        </ng-container>

        <!-- Header and Rows -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="clickable-row"></tr>

        <!-- No Data Message -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell no-data-message" colspan="3">
            No data available
          </td>
        </tr>
      </table>
    </div>
    <div *ngIf="total_count > 0">
      <app-pagination [datafromparent]="total_count" (onDatePicked)="pagination($event)"></app-pagination>
    </div>
</section>

<!-- Create Section Dialog Template -->
<ng-template #createSectionDialog>
  <h1 mat-dialog-title>Add Section</h1>
  <form (ngSubmit)="onSubmit()" [formGroup]="createsectionForm">
    <div mat-dialog-content>
      <div *ngFor="let question of createsection" class="form-question">
        <app-question [question]="question" [form]="createsectionForm"></app-question>
      </div>
    </div>
    <div mat-dialog-actions class="dialog-actions">
      <button mat-flat-button color="warn" class="cancel_button" (click)="onSaveClick()">Cancel</button>
      <button mat-flat-button color="primary" class="submit_button" [disabled]="!createsectionForm.valid">Submit</button>
    </div>
  </form>
</ng-template>


