<!-- <ng-container *ngIf="lookupdata.length > 0"> -->
    <section class="mx-3">
        <ng-container>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h3 class="m-0">Acadamic  Years List</h3>
            </div>
            <div *ngIf="actiondata.create">
              <button
                mat-raised-button
                color="primary"
                (click)="openAddAcademicsDialog()"
                class="px-5 add_button"
              >Add</button>
            </div>
          </div>
          <div class="position-relative">
            @if (isLoadingResults || isRateLimitReached) {
            <div class="loading-shade">
              @if (isLoadingResults) {
              <mat-spinner class="mat_spinner"></mat-spinner>
              } @if (isRateLimitReached) {
              <div class="rate-limit-reached">
                GitHub's API rate limit has been reached. It will be reset in one
                minute.
              </div>
              }
            </div>
            }
            <ng-container *ngIf="actiondata.list_view">
            <div class="mt-2 table-container">
              <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <!-- <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox
                      (change)="$event ? toggleAllRows() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [aria-label]="checkboxLabel()"
                    >
                    </mat-checkbox>
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <mat-checkbox
                      (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)"
                      [aria-label]="checkboxLabel(row)"
                    >
                    </mat-checkbox>
                  </td>
                </ng-container>
                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef>Sl.no</th>
                  <td mat-cell *matCellDef="let element">{{ element.id }}</td>
                </ng-container> -->
                <ng-container matColumnDef="regulation_code">
                  <th mat-header-cell *matHeaderCellDef>Regulation</th>
                  <td mat-cell *matCellDef="let element">{{ element.academic_regulation_code }}</td>
                </ng-container>
                <ng-container matColumnDef="course">
                  <th mat-header-cell *matHeaderCellDef>Course</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.course_name}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="Academic_Year">
                    <th mat-header-cell *matHeaderCellDef>Acadamic Year</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.start_date | date: 'yyyy' }} - {{ element.end_date | date: 'yyyy' }}
                    </td>
                  </ng-container>
                <ng-container matColumnDef="code">
                  <th mat-header-cell *matHeaderCellDef>Regulation Code</th>
                  <td mat-cell *matCellDef="let element">{{ element.code }}</td>
                </ng-container>
               
                <ng-container matColumnDef="courses">
                  <th mat-header-cell *matHeaderCellDef>Courses</th>
                  <td mat-cell *matCellDef="let element">
                    <div *ngFor="let course of element.courses">
                      {{ course.name }}
                    </div>
                  </td>
                </ng-container>
      
                <ng-container *ngFor="let course of courseData">
                  <ng-container matColumnDef="{{ course.field }}">
                    <th mat-header-cell *matHeaderCellDef>{{ course.header }}</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element[course.field] }}
                    </td>
                  </ng-container>
                </ng-container>
      
                <ng-container matColumnDef="description">
                  <th mat-header-cell *matHeaderCellDef>Description</th>
                  <td mat-cell *matCellDef="let element">
                    <div class="description-container">
                      {{ element.description }}
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef>Status</th>
                  <td mat-cell *matCellDef="let element">
                    <div class="status-container">
                      {{ element.status }}
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef>Actions</th>
                  <td mat-cell *matCellDef="let row">
                    <button mat-raised-button  class="action_button" (click)="showsemesterdetails(row)">
                      View
                    </button>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                  (click)="selection.toggle(row)"
                ></tr>
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data</td>
                </tr>
              </table>
            </div>
            <div *ngIf="total_count > 5">
              <app-pagination [datafromparent]="total_count" (onDatePicked)="pagination($event)"></app-pagination>
            </div>
          </ng-container>
          </div>
        </ng-container>
      <!--
        </ng-container>
        <ng-container *ngIf="lookupdata.length <= 0">
        <p>No data Found</p>
        </ng-container>
      
        <ng-container *ngIf="showaddflag">
          <div class="d-flex justify-content-between align-items-center mb-2">
            <div>
              <h4 class="m-0">Add Courses</h4>
            </div>
            <div>
              <button mat-raised-button color="primary" (click)="showadd()" class="px-5">Back</button>
            </div>
          </div>
          <div class="container">
            <form (ngSubmit)="onSubmit()" [formGroup]="form">
                <div class="row">
                    <div *ngFor="let question of questions" class="col-sm-6">
                        <app-question [question]="question" [form]="form"></app-question>
                    </div>
                </div>
                <div class="form-row">
                    <button mat-flat-button color="primary" class="px-5" type="submit" [disabled]="!form.valid">
                        Save
                    </button>
                </div>
            </form>
          
            
              <div *ngIf="payLoad" class="form-row">
                <strong>Saved the following values</strong><br>{{payLoad}}
              </div>
            </div>
          </ng-container>-->
      </section>
      