<section class="mx-3">
  <app-toast></app-toast>
  <ng-container *ngIf="!showaddflag">
    <div>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h4 class="m-0">Student Details</h4>
        </div>
        <div *ngIf="actiondata.create" class="d-flex button-row">
          <div class="px-3">
            <div class="bulk_button">
              <input
                #csvInput
                hidden="true"
                type="file"
                onclick="this.value=null"
                (change)="csvInputChange($event)"
              />
              <button
                mat-raised-button
                class="bulk_button_add"
                (click)="csvInput.click()"
              >
                <img
                  src="../../../assets/icons/fileupload.svg"
                  class="me-2"
                  alt="upload icon"
                />
                <span>Bulk Upload</span>
              </button>
              <button
                mat-raised-button
                class="m-0 p-0 bulk_button_view"
                [matMenuTriggerFor]="menu"
                color="primary"
              >
                <mat-icon class="m-0">expand_more</mat-icon>
              </button>
            </div>
            <mat-menu #menu="matMenu" class="download_menu">
              <button
                mat-menu-item
                (click)="
                  openUploadDialog('details', 'STUDENTS_BULKUPLOAD_TEMPLATE')
                "
                class="submit_button"
              >
                Upload Student Details
              </button>
              <button
                class="submit_button"
                mat-menu-item
                (click)="openUploadDialog('images', 'studentImages')"
              >
                Upload Student Images
              </button>
            </mat-menu>
          </div>
          <div>
            <button
              mat-raised-button
              color="primary"
              (click)="showadd(true)"
              class="px-5 add_button"
            >
              Add
            </button>
          </div>
        </div>
      </div>
      <ng-container *ngIf="actiondata.list_view">
        <div class="table-container">
          <div class="d-flex">
            <div class="m-2 filter_icon">
              <button
                mat-button
                style="color: rgb(19, 19, 19)"
                [matMenuTriggerFor]="filterMenu"
                class="image_choose_button"
              >
                <img
                  src="../../../assets/icons/filter_Icon.svg"
                  alt="filter_icon"
                />
                Filter
              </button>
              <mat-menu #filterMenu="matMenu" (closed)="menuClosed($event)">
                <div class="p-3" (click)="$event.stopPropagation()">
                  <form
                    (ngSubmit)="filterStudents($event)"
                    [formGroup]="StudentFilterForm"
                  >
                    <div *ngFor="let question of StudentFilterFormQuestions">
                      <app-question
                        [question]="question"
                        [form]="StudentFilterForm"
                      ></app-question>
                    </div>
                    <div class="p-3" (click)="$event.stopPropagation()">
                      <button
                        mat-flat-button
                        class="submit_button"
                        style="background-color: #3f51b5; color: white"
                        type="submit"
                      >
                        <mat-icon>filter_list_alt</mat-icon>Filter
                      </button>
                      <button
                        mat-flat-button
                        class="m-2 cancel_button"
                        style="background-color: #f52d2d; color: white"
                        (click)="clearFilter()"
                      >
                        Clear
                      </button>
                    </div>
                  </form>
                </div>
              </mat-menu>
            </div>
            <div class="m-2">
              <mat-icon
                class="material-symbols-outlined"
                style="color: rgb(160, 160, 160)"
                >more_vert</mat-icon
              >
            </div>
          </div>

          <table
            mat-table
            [dataSource]="dataSource"
            matSort
            class="mat-elevation-z8"
          >
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  (change)="$event ? toggleAllRows() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                  [aria-label]="checkboxLabel()"
                >
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(row) : null"
                  [checked]="selection.isSelected(row)"
                  [aria-label]="checkboxLabel(row)"
                >
                </mat-checkbox>
              </td>
            </ng-container>

            <!-- Position Column -->
            <!-- <ng-container matColumnDef="index">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>No.</th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
          </ng-container> -->
            <!-- Name Column -->
            <ng-container matColumnDef="Name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
              <td mat-cell *matCellDef="let element">
                {{ element.first_name }} {{ element.last_name }}
              </td>
            </ng-container>
            <ng-container matColumnDef="Course_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Course</th>
              <td mat-cell *matCellDef="let element">
                {{ element.course_name }}
              </td>
            </ng-container>
            <ng-container matColumnDef="phone_number">
              <th mat-header-cell *matHeaderCellDef>Mobile Number</th>
              <td mat-cell *matCellDef="let element">
                {{ element.phone_number }}
              </td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef>Email</th>
              <td mat-cell *matCellDef="let element">{{ element.email }}</td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="Dost_ID">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Dost ID</th>
              <td mat-cell *matCellDef="let element">{{ element.Dost_ID }}</td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="Roll_No">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Roll No</th>
              <td mat-cell *matCellDef="let element">
                {{ element.roll_number }}
              </td>
            </ng-container>
            <!-- <ng-container matColumnDef="Department">
          <th mat-header-cell *matHeaderCellDef>Department</th>
          <td mat-cell *matCellDef="let element">{{ element.course_name }}</td>
        </ng-container> -->
            <ng-container matColumnDef="Academic_Year">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Academic Year
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.Academic_Year }}
              </td>
            </ng-container>
            <ng-container matColumnDef="Action" stickyEnd>
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
              <td mat-cell *matCellDef="let element">
                <div class="d-flex align-items-center">
                  <p
                    *ngIf="actiondata.view"
                    (click)="gotostudentview(element)"
                    class="mb-0 cursor_pointer view_button"
                  >
                    View
                  </p>

                  <!-- 3-dots menu for edit option -->
                  <ng-container *ngIf="actiondata.edit">
                    <button
                      class="action_button"
                      mat-icon-button
                      [matMenuTriggerFor]="menu"
                      aria-label="Actions"
                    >
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button
                        class="update_button"
                        mat-menu-item
                        (click)="editForm(element)"
                      >
                        <!-- <mat-icon>edit</mat-icon> -->
                        <span>Edit</span>
                      </button>
                    </mat-menu>
                  </ng-container>
                </div>
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns"
              (click)="selection.toggle(row)"
            ></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No data</td>
            </tr>
          </table>
        </div>
        <div *ngIf="total_count > 5">
          <app-pagination
            [datafromparent]="total_count"
            (onDatePicked)="pagination($event)"
          ></app-pagination>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="showaddflag">
    <div class="">
      <div class="d-flex align-items-center">
        <div>
          <button
            mat-icon-button
            class="back_icon cancel_button me-2"
            (click)="showadd(false)"
          >
            <mat-icon>arrow_back</mat-icon>
          </button>
        </div>
        <!-- <div>
          <button (click)="editform()">edit</button>
        </div> -->
        <div>
          <h4 class="m-0">
            {{ isEditMode ? "Edit Students" : "Add Students" }}
          </h4>
        </div>
      </div>
      <div class="wrapper">
        <mat-stepper
          labelPosition="bottom"
          [linear]="true"
          #stepper
          class="form-stepper bg-transparent"
        >
          <mat-step label="Step 1">
            <div class="mt-4">
              <h4>1. Student Details</h4>
            </div>
            <form (ngSubmit)="onSubmit()" [formGroup]="StudentPersonalForm">
              <div class="row">
                <div class="col-sm-8">
                  <div class="row">
                    <div
                      class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6"
                    >
                      <app-question
                        [question]="StudentPersonalFormQuestions[0]"
                        [form]="StudentPersonalForm"
                      ></app-question>
                    </div>
                    <div
                      class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6"
                    >
                      <app-question
                        [question]="StudentPersonalFormQuestions[1]"
                        [form]="StudentPersonalForm"
                      ></app-question>
                    </div>
                    <div
                      class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6"
                    >
                      <app-question
                        [question]="StudentPersonalFormQuestions[2]"
                        [form]="StudentPersonalForm"
                      ></app-question>
                    </div>
                    <div
                      class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6"
                    >
                      <app-question
                        [question]="StudentPersonalFormQuestions[3]"
                        [form]="StudentPersonalForm"
                      ></app-question>
                    </div>
                    <div
                      class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6"
                    >
                      <app-question
                        [question]="StudentPersonalFormQuestions[4]"
                        [form]="StudentPersonalForm"
                      ></app-question>
                    </div>
                    <div
                      class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6"
                    >
                      <app-question
                        [question]="StudentPersonalFormQuestions[5]"
                        [form]="StudentPersonalForm"
                      ></app-question>
                    </div>
                  </div>
                </div>
                <div
                  class="col-sm-4 d-flex align-items-center justify-content-center"
                >
                  <div class="m-2">
                    <input
                      #csvInput
                      hidden="true"
                      type="file"
                      onclick="this.value=null"
                      (change)="imageInputChange($event)"
                    />
                    <div>
                      <img
                        *ngIf="!selectedImageUrl; else showImage"
                        src="../../../assets/icons/Upload photo icon.svg"
                        aria-hidden="false"
                        (click)="csvInput.click()"
                        style="cursor: pointer; width: 200px; height: 200px"
                      />
                      <ng-template #showImage>
                        <img
                          [src]="selectedImageUrl"
                          alt="Selected Image"
                          (click)="csvInput.click()"
                          style="width: 200px; height: 200px; cursor: pointer"
                        />
                      </ng-template>
                      <p class="text-center mt-1">Upload Your Photo</p>
                    </div>
                    <p *ngIf="fileName" class="text-center mt-1">
                      Selected file: {{ fileName }}
                    </p>
                  </div>
                </div>
                <div
                  *ngFor="let question of filterdata"
                  class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4"
                >
                  <app-question
                    [question]="question"
                    [form]="StudentPersonalForm"
                  ></app-question>
                </div>
              </div>
            </form>
          </mat-step>

          <mat-step label="Step 2">
            <div class="mb-2">
              <ng-container *ngIf="educationaddformarry.length > 0">
                <div class="row">
                  <div
                    class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4"
                    *ngFor="
                      let arrayform of educationaddformarry;
                      let i = index
                    "
                  >
                    <div class="card position-relative">
                      <div class="m-2 px-2">
                        <div>
                          <b>{{ arrayform.qualification_id }}</b>
                        </div>
                        <div>{{ arrayform.course_name }}</div>
                        <div class="mt-3">
                          <div>
                            Year of passing :
                            <span>{{
                              arrayform.end_year | date : "yyyy"
                            }}</span>
                          </div>
                          <div>
                            Pass Percentage :
                            <span>{{ arrayform.percentage }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="position-absolute top-0 end-0">
                        <button
                          mat-icon-button
                          class="action_button"
                          [matMenuTriggerFor]="menu"
                          aria-label="edit"
                        >
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                          <button
                            mat-menu-item
                            class="update_button"
                            (click)="editarrayform(arrayform, i)"
                          >
                            <span>Edit</span>
                          </button>
                          <button
                            mat-menu-item
                            class="delete_button"
                            (click)="deletearrayform(i)"
                          >
                            <span>Delete</span>
                          </button>
                        </mat-menu>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <form [formGroup]="StudentEducationForm">
              <div class="mt-4">
                <h4>2. Education Details</h4>
              </div>
              <div class="row">
                <div
                  *ngFor="let question of StudentEducationalFormQuestions"
                  class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4"
                >
                  <app-question
                    [question]="question"
                    [form]="StudentEducationForm"
                  ></app-question>
                </div>
              </div>
              <div class="d-flex">
                <button
                  class="me-3 cancel_button"
                  type="submit"
                  mat-raised-button
                >
                  Cancel
                </button>
                <!-- <button type="submit" mat-raised-button color="primary" (click)="arraySubmit()" [disabled]="!StudentEducationForm.valid">
                  Add Details
                </button> -->
                <ng-container *ngIf="editeducationarrayflag">
                  <button
                    type="submit"
                    mat-raised-button
                    color="primary"
                    class="submit_button"
                    (click)="arraySubmit()"
                    [disabled]="!StudentEducationForm.valid"
                  >
                    Add Details
                  </button>
                </ng-container>
                <ng-container *ngIf="!editeducationarrayflag">
                  <button
                    type="submit"
                    mat-raised-button
                    color="primary"
                    class="update_button"
                    (click)="editarrySubmit()"
                    [disabled]="!StudentEducationForm.valid"
                  >
                    Update Details
                  </button>
                </ng-container>
              </div>
            </form>
          </mat-step>

          <mat-step label="Step 3" [stepControl]="StudentAddressForm">
            <div class="mt-4">
              <h4>3. Family Details</h4>
            </div>
            <!-- <form (ngSubmit)="onSubmit()" [formGroup]="StudentAddressForm"> -->
            <div class="row">
              <div
                *ngFor="let question of StudentFamilyFormQuestions"
                class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4"
              >
                <app-question
                  [question]="question"
                  [form]="StudentFamilyForm"
                ></app-question>
              </div>
              <div>
                <div>
                  <h4>Present Address</h4>
                </div>
                <form [formGroup]="StudentPresentAddressForm">
                  <div class="row">
                    <div
                      *ngFor="let question of StudentAddressFormQuestions"
                      class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4"
                    >
                      <app-question
                        [question]="question"
                        [form]="StudentPresentAddressForm"
                      ></app-question>
                    </div>
                  </div>
                </form>
              </div>
              <div>
                <mat-checkbox
                  class="mb-3"
                  [(ngModel)]="checked"
                  [(indeterminate)]="indeterminate"
                  (click)="addresscheckbox()"
                  [disabled]="!StudentPresentAddressForm.valid"
                >
                  Permanent Address is same as Present Address
                </mat-checkbox>
              </div>
              <div>
                <div>
                  <h4>Permanent Address</h4>
                </div>
                <form [formGroup]="StudentPermanentAddressForm">
                  <div class="row">
                    <div
                      *ngFor="
                        let question of StudentAddressFormQuestions;
                        let i = index
                      "
                      class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4"
                    >
                      <app-question
                        [question]="changingids(question, i)"
                        [form]="StudentPermanentAddressForm"
                      ></app-question>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <!-- </form> -->
          </mat-step>
        </mat-stepper>
      </div>
      <!-- remove the next button and add the back button -->
      <div class="form-row d-flex justify-content-end my-3 py-2 bg-white">
        <!-- Back button always visible -->
        <button
          mat-raised-button
          color="primary"
          class="px-5 previous_button mx-2"
          (click)="stepper.previous()"
          [disabled]="stepper.selectedIndex === 0"
        >
          Back
        </button>
        <button
          mat-raised-button
          color="primary"
          class="px-5 submit_button mx-2"
          *ngIf="
            stepper.selectedIndex === stepper.steps.length - 1 &&
            StudentPresentAddressForm.valid &&
            StudentPermanentAddressForm.valid
          "
          (click)="onSubmit()"
        >
          {{ isEditMode ? "Update" : "Submit" }}
        </button>
        <!-- <button mat-raised-button color="primary" class="px-5 mx-2"
          *ngIf="stepper.selectedIndex !== stepper.steps.length - 1" (click)="onSubmit()"
          [disabled]="!StudentPersonalForm.valid">
          Save
        </button> -->
        <button
          mat-raised-button
          color="primary"
          class="px-5 next_button mx-2"
          *ngIf="stepper.selectedIndex !== stepper.steps.length - 1"
          (click)="stepper.next()"
          [disabled]="!StudentPersonalForm.valid"
        >
          Next
        </button>
      </div>
    </div>
  </ng-container>
</section>
