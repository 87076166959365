<section class="mx-3">
    <div class="d-flex justify-content-between align-items-center mx-4 py-3">
        <div>
            <h2 class="m-0">Add Streams</h2>
        </div>
        <div>
            <button mat-raised-button color="primary" (click)="closeDialog()" class="px-5 cancel_button">Back</button>
        </div>
    </div>
    <div class="container">
        <form (ngSubmit)="onSubmit()" [formGroup]="form">
            <div class="row">
                <div *ngFor="let question of questions" class="col-sm-6">
                    <app-question [question]="question" [form]="form"></app-question>
                </div>
            </div>
            <div class="form-row">
                <button mat-raised-button color="primary" class="px-5 my-4 submit_button" type="submit" [disabled]="!form.valid">Submit</button>
            </div>
        </form>
        <!-- <div *ngIf="payLoad" class="form-row">
        <strong>Saved the following values</strong><br>{{payLoad}}
      </div> -->
    </div>
</section>