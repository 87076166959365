<section class="container">
  <h2 class="text-start m-4">Add Banners</h2>
  <mat-divider></mat-divider>
  <form [formGroup]="bannersForm" class="pt-3">
    <div class="row">
      <div class="col-md-4 col-sm-6 d-flex flex-column align-items-start">
        <input
          #fileInput
          hidden="true"
          type="file"
          color="primary"
          (change)="imageInputChange($event)"
        />
        <button mat-raised-button color="primary" (click)="fileInput.click()">
          Choose Image
        </button>
        <p *ngIf="!fileName && !selectedImageUrl" class="text-start mt-1">
          <span class="text-danger">*</span>Upload Banner Image
        </p>
        <p *ngIf="fileName && selectedImageUrl" class="mt-2">Selected file: {{ fileName }}</p>
      </div>
      <div class="col-md-2 col-sm-6 d-flex align-items-center mt-3 mt-md-0">
        <!-- Display Existing Image if Available -->
        <img *ngIf="selectedImageUrl" 
             [src]="selectedImageUrl" 
             alt="Selected Image" 
             style="width: 4.5rem; height: 4.5rem; cursor: pointer">
      </div>
      <div *ngFor="let question of questions" class="col-12 mb-3">
        <div class="form-group">
          <app-question
            [question]="question"
            [form]="bannersForm"
          ></app-question>
        </div>
      </div>
    </div>
  </form>
  <mat-dialog-actions align="center" class="bg-white bottom-0 end-0">
    <div class="d-flex gap-2">
      <button
        mat-raised-button
        (click)="closeDialog(false)"
        class="px-5"
        color="warn"
      >
        Cancel
      </button>
      <button
        mat-raised-button
        color="primary"
        class="px-5  submit_button"
        type="submit"
        [disabled]="!bannersForm.valid"
        (click)="onSubmit()"
      >
        Submit
      </button>
    </div>
  </mat-dialog-actions>
</section>
