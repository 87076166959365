<div class="container">
  <ng-container *ngIf="!showaddflag">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h3>Assigned Leaves</h3>
      </div>
      <div *ngIf="actiondata.create">
        <button mat-raised-button color="primary" (click)="showadd()" class="px-5">Add</button>
      </div>
    </div>
    <!-- <ng-container *ngIf="actiondata.list_view"> -->
      <div *ngIf="actiondata.list_view" class="mt-2 table-container">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? toggleAllRows() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="Employee_Name">
            <th mat-header-cell *matHeaderCellDef> Employee Name</th>
            <td mat-cell *matCellDef="let element"> {{element.employee_id}} </td>
          </ng-container>
          <!-- Name Column -->
          <ng-container matColumnDef="leave_type">
            <th mat-header-cell *matHeaderCellDef>Leave Type</th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <ng-container matColumnDef="number_of_remaining_leaves">
            <th mat-header-cell *matHeaderCellDef>Number Of Remaining Leaves</th>
            <td mat-cell *matCellDef="let element"> {{element.number_of_remaining_leaves}} </td>
          </ng-container>
          <!-- Symbol Column -->
          <ng-container matColumnDef="number_of_allowed_leaves">
            <th mat-header-cell *matHeaderCellDef>Number Of Allowed Leaves</th>
            <td mat-cell *matCellDef="let element"> {{element.number_of_allowed_leaves}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
          </tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data</td>
          </tr>
        </table>
      </div>
    <!-- </ng-container> -->
  </ng-container>
  <ng-container *ngIf="showaddflag">
    <div>
      <h1>Leaves Assingned</h1>
    </div>
    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <div class="row">
        <div *ngFor="let question of questions" class="col-sm-6">
          <app-question [question]="question" [form]="form"></app-question>
        </div>
      </div>
      <div class="mt-3 text-end">
        <button mat-raised-button color="primary" class="px-5 submit_button" type="submit" [disabled]="!form.valid">Save</button>
      </div>
      <!-- <div class="mt-3 text-end">
        <button mat-raised-button color="primary" class="px-5" type="button" (click)="editform()">Edit</button>
      </div> -->
    </form>
  </ng-container>
</div>