import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { CreatepageserviceService } from '../../../../inputfileds/form_services/web_activities/createpageservice.service';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Inputfiledsbase } from '../../../../inputfileds/models/inputfiledsbase';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule, DatePipe } from '@angular/common';
import { QuestionControlService } from '../../../../inputfileds/form_services/question-control.service';
import { DynamicFormQuestionComponent } from "../../../../inputfileds/dynamic-form-question/dynamic-form-question.component";
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-add-activity-dialog',
  templateUrl: './addactivitydialog.component.html',
  styleUrls: ['./addactivitydialog.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    CommonModule,
    MatDialogModule,
    ReactiveFormsModule,
    DynamicFormQuestionComponent,
    MatButtonModule
]
})
export class AddactivitydialogComponent implements OnInit, OnDestroy {
  questions: Inputfiledsbase<string>[] = [];
  questions$: Observable<Inputfiledsbase<string>[]> | null = null;
  pagecreationForm: FormGroup;
  images: File[] = [];
  previewUrl: string[] = [];
  backendImages: string[] = []; // To hold images fetched from backend
  currentSlide: number = 0;
  private subscription = new Subscription();
  editflag: boolean = false;
  baseImageUrl = `${this.apiServices.environmentPath}/`; // Adjust this according to your base URL for images
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddactivitydialogComponent>,
    private createpageServices: CreatepageserviceService,
    private apiServices: ApiservicesService,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: { pageId: string; activityData: any },
    // private datePipe: DatePipe
  ) {
    this.pagecreationForm = this.fb.group({
      heading: ['', Validators.required],
      description: ['', Validators.required],
      date: ['', Validators.required],
      activityImages: ['', Validators.required],
      web_page_id: [[]],
      parent_web_page_id: ['', Validators.required],
    });
    console.log(`Image URL: ${this.baseImageUrl}`);
    if (data.activityData) {
      this.pagecreationForm.patchValue({
        heading: data.activityData.heading,
        description: data.activityData.description,
        date: data.activityData.date,
        web_page_id: data.activityData.web_page_id || [],
        parent_web_page_id: data.activityData.parent_web_page_id,
      });
      this.previewUrl = (data.activityData.image_urls || []).map((url: string) => `${this.baseImageUrl}${url}`);
      
      this.editflag = true;
    }
    
    this.loadQuestions();
  }

  ngOnInit(): void {
    
  }

  private loadQuestions(): void {
    this.questions$ = this.createpageServices.getCreateEvents();
    this.subscription.add(
      this.questions$.subscribe({
        next: (q) => (this.questions = q || []),
        error: (err) => console.error('Error loading questions:', err),
      })
    );
  }

  onImageUpload(event: any): void {
    const input = event.target as HTMLInputElement;
    const files = input.files;

    if (files && files.length > 0) {
      const newImages = Array.from(files);
      
      newImages.forEach((file) => {
        const reader = new FileReader();
        
        reader.onload = (e: any) => {
          if (e.target?.result) {
            this.previewUrl.push(e.target.result as string);
          }
        };
        
        reader.readAsDataURL(file);
        
        if (file.size > 5 * 1024 * 1024) {
          alert('File size exceeds 5MB');
          return;
        }
      });

      this.images.push(...newImages);
    }
  }

  deleteImage(index: number): void {
    if (index >= this.images.length) return; // Prevent out of bounds access
    this.images.splice(index, 1);
    this.currentSlide = Math.max(0, this.currentSlide - 1);
  }

  prevSlide(): void {
    this.currentSlide = (this.currentSlide - 1 + this.previewUrl.length) % this.previewUrl.length;
  }

  nextSlide(): void {
    this.currentSlide = (this.currentSlide + 1) % this.previewUrl.length;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    const organizationData = sessionStorage.getItem('user');
    
    let organizationId: number | null = null;
    
    if (organizationData) {
      try {
        const parsedData = JSON.parse(organizationData);
        organizationId = parsedData.data?.organization_id ?? null;
      } catch (error) {
        console.error('Error parsing organization data:', error);
      }
    }

    if (!organizationId || !this.images.length) {
      console.error('Organization ID not found or no images uploaded.');
      return;
    }
    const webPageIds = (this.pagecreationForm.value.web_page_id || []).map((item: any) => item.id);
    // const formattedDate = this.datePipe.transform(this.pagecreationForm.value.date, 'yyyy/MM/dd');
    const pageData = {
      organization_id: organizationId,
      heading: this.pagecreationForm.value.heading,
      description: this.pagecreationForm.value.description,
      date: this.pagecreationForm.value.date,
      web_page_id: webPageIds,
      parent_web_page_id: this.data.pageId,
    };

    const formDataToSend = new FormData();
    
    this.images.forEach((file) => formDataToSend.append('activityImages', file));
    
    Object.entries(pageData).forEach(([key, value]) => formDataToSend.append(key, value));

    if (this.editflag) {
      this.updatePage(formDataToSend);
    } else {
      this.createPage(formDataToSend);
    }
  }

  private createPage(pageData: FormData): void {
    this.apiServices.createActivitiesEventData(pageData).subscribe({
      next: (response) => {
        console.log('Page created successfully:', response);
        this.dialogRef.close(response);
      },
      error: (err) => console.error('Error creating page:', err),
    });
  }

  private updatePage(pageData: FormData): void {
    this.apiServices.updateActivityEvents(pageData).subscribe({
      next: (response) => {
        console.log('Activity updated successfully:', response);
        this.dialogRef.close(response);
      },
      error: (err) => console.error('Error updating activity:', err),
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
