<section>
  <app-toast></app-toast>
    <div *ngIf="!semisterflag">
    <mat-dialog-content class="mat-typography">
      <div class="d-flex align-items-center">
        <div>
          <button mat-icon-button class="back_icon cancel_button me-2" (click)="closeDialog(false)"><mat-icon>arrow_back</mat-icon></button>
        </div>
        <div>
          <h4 class="m-0">Add Academic Year</h4>
        </div>
      </div>
      <mat-divider class="mt-2"></mat-divider>
      <div class="mt-3">
        <div *ngIf="academicsform.errors?.['dateRangeInvalid']" class="error-message">
          End Year must be greater than the Start Year and not more than one year later.
        </div>
        <form [formGroup]="academicsform">
          <div class="row m-0">
            <div *ngFor="let question of questions" class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
              <app-question [question]="question" [form]="academicsform"></app-question>
            </div>
          </div>
        </form>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="position-absolute bottom-0 end-0">
      <div class="form-row">
        <button mat-raised-button (click)="closeDialog(false)" class="px-5 cancel_button">
          Cancel
        </button>
        <!-- <button mat-raised-button (click)="editcourse()" class="px-5">
          edit
        </button> -->
        <!-- button mat-raised-button (click)="gotosemisters()" class="px-5">
          GoTO
        </button> -->
        <button mat-raised-button color="primary" (click)="onSubmit()" class="px-5 submit_button" type="submit"
          [disabled]="!academicsform.valid">
          Save
        </button>
      </div>
    </mat-dialog-actions>
    </div>
    <!-- <div *ngIf="semisterflag">
        
      [courseData]="coursedata"
      <app-sections (backtocourseform)="gotosemisters()"></app-sections>
    </div> -->
  </section>