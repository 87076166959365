<section class="mx-3">
  <div class="organization-details">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <div>
        <h2 class="organization-title">Organisation</h2>
      </div>
  
        <div class="add-sub-organization mt-3">
          <button mat-raised-button color="primary" class="px-5" (click)="openDialog()">
            + Add Sub Organisation
          </button>
        </div>
      
    </div>
    <div class="organization-info-card">
      <div class="card-header">
        <div class="organization-header">
          <h3 class="organization-name">Dr. B R Ambedkar Organisation</h3>
          <p class="organization-location">Hyderabad</p>
        </div>
        <button mat-icon-button class="options-menu">
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>

      <div class="contact-info">
        <div class="contact-item">
          <mat-icon>call</mat-icon>
          <span>9876543210</span>
        </div>
        <div class="contact-item">
          <mat-icon>email</mat-icon>
          <span>dbreai&#64;gmail.com</span>
        </div>
        <div class="contact-item">
          <mat-icon>location_on</mat-icon>
          <span
            >4th Floor, Jayabheri Enclave, Sinman Towers, Hyderabad, Telangana,
            500085.</span
          >
        </div>
      </div>
    </div>
  </div>
  <h3 class="organization-title mt-4">Sub-Organisation Details</h3>
  <div class="sub-organization-details mt-2">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="Sl_No">
        <th mat-header-cell *matHeaderCellDef>Sl.No</th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">
          {{ element.organization_name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="city">
        <th mat-header-cell *matHeaderCellDef>Location</th>
        <td mat-cell *matCellDef="let element">{{ element.city }}</td>
      </ng-container>

      <ng-container matColumnDef="point_of_contact">
        <th mat-header-cell *matHeaderCellDef>Point Of Contact</th>
        <td mat-cell *matCellDef="let element">
          {{ element.designation }}
        </td>
      </ng-container>

      <ng-container matColumnDef="contact_number">
        <th mat-header-cell *matHeaderCellDef>Contact Number</th>
        <td mat-cell *matCellDef="let element">{{ element.contact_number }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button (click) = "showcollegedetails()">
            <mat-icon>visibility</mat-icon>
          </button>
          <button mat-icon-button>
            <mat-icon>more_vert</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data</td>
      </tr>
    </table>
  </div>
  <ng-template #addForm>
    <div class="d-flex justify-content-between align-items-center mb-2">
      <div>
        <h4 class="m-0">Leave Type</h4>
      </div>
      <div>
        <button mat-raised-button color="primary" (click)="showadd()" class="px-5">Back</button>
      </div>
    </div>
    <div class="container">
      <form [formGroup]="form">
          <div class="row">
              <div *ngFor="let question of questions" class="col-sm-6">
                  <app-question [question]="question" [form]="form"></app-question>
              </div>
          </div>
          <div class="form-row">
      <button
      mat-raised-button
      color="primary"
      class="px-5 submit_button"
      type="submit"
      [disabled]="!form.valid"
    >
      Submit
    </button>
          </div>
      </form>
    
      
        <!-- <div *ngIf="payLoad" class="form-row">
          <strong>Saved the following values</strong><br>{{payLoad}}
        </div> -->
      </div>
  </ng-template>
  </section>


