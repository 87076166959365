<section class="mx-3">
  <mat-dialog-content class="mat-typography">
    <div class="d-flex justify-content-between">
      <div>
        <h2 mat-dialog-title>Shift Timings</h2>
      </div>
      <div class="cursor_pointer" (click)="Canceldailog()">
        <mat-icon
          aria-hidden="false"
          aria-label="Example home icon"
          fontIcon="close"
        ></mat-icon>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="p-3">
      <div class="position-relative pt-2">
        <div class="form">
          <ng-container>
            <div class="container">
              <form  [formGroup]="shifttimeform">
                <div class="row">
                  <div *ngFor="let question of questions" class="mb-3 col-sm-6">
                    <app-question
                      [question]="question"
                      [form]="shifttimeform"
                    ></app-question>
                  </div>
                  <!-- <div class="col-sm-6">
                      <app-question
                        [question]="shiftEndTimeQuestion"
                        [form]="shiftForm"
                      ></app-question>
                    </div> -->
                </div>
              </form>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <section class="p-3">
      <div>
        <h2 mat-dialog-title>Break Timings</h2>
        <mat-divider></mat-divider>
      </div>
      <div class="position-relative pt-2">
        <div class="form">
          <ng-container>
            <div class="container">
              <form (ngSubmit)="!editflag ? arraySubmit() : editarraySubmit()" [formGroup]="breaktimeform">
                <div class="row">
                  <div *ngFor="let question of question" class="mb-3 col-sm-6">
                    <app-question
                      [question]="question"
                      [form]="breaktimeform"
                    ></app-question>
                  </div>
                  <div *ngIf="!editflag" class="d-flex">
                    <!-- <button type="button" class="me-3" (click)="reseteducationform()" mat-raised-button>Cancel</button> -->
                    <button type="submit" mat-raised-button color="primary" class="add_button"
                      [disabled]="!breaktimeform.valid">Add Details</button>
                  </div>
                  <div *ngIf="editflag" class="d-flex">
                    <!-- <button type="button" class="me-3" (click)="reseteducationform()" mat-raised-button>Cancel</button> -->
                    <button type="submit" mat-raised-button color="primary" class="update_button"
                      [disabled]="!breaktimeform.valid">Edit Details</button>
                  </div>
                  <!-- <div class="col-sm-6">
                      <app-question
                        [question]="breakEndTimeQuestion"
                        [form]="breakForm"
                      ></app-question>
                    </div> -->
                </div>
              </form>
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="breakaddformarry.length > 0">
          <div class="row mt-3">
            <div class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4"
              *ngFor="let arrayform of breakaddformarry; let i=index">
              <div class="card position-relative">
                <div class="m-2 px-2">
                  <div>{{arrayform.break_type}}</div>
                  <!-- <div class="mt-3"> -->
                    <div>Start Time <span>{{arrayform.break_start_time}}</span></div>
                    <div>End Time : <span>{{arrayform.break_end_time}}</span> </div>
                  <!-- </div> -->
                </div>
                <!-- <div class="d-flex align-items-end">
                  <div><button mat-raised-button color="primary" class="mx-2"
                      (click)="editarrayform(arrayform)">edit</button></div>
                  <div><button mat-raised-button color="primary" (click)="deletearrayform(i)">delete</button>
                  </div>
                </div> -->
                <div class="position-absolute top-0 end-0">
                  <button class="action_button" mat-icon-button [matMenuTriggerFor]="menu" aria-label="edit">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item class="update_button" (click)="editarrayform(arrayform, i)">
                      <span>Edit</span>
                    </button>
                    <button mat-menu-item class="delete_button" (click)="deletearrayform(i)">
                      <span>Delete</span>
                    </button>
                  </mat-menu>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </section>
  </mat-dialog-content>
  <mat-dialog-actions align="end"
    >'
    <div class="form-row">
      <button mat-raised-button (click)="Canceldailog()" class="px-5 cancel_button">
        Cancel
      </button>
      <button
  mat-raised-button
  color="primary"
  (click)="onSubmit()"
  class="px-5 submit_button"
  type="submit"
  [disabled]="!shifttimeform.valid || !breakaddformarry.length"
>
  Save
</button>
    </div>
  </mat-dialog-actions>
</section>
