import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { FrontpageService } from '../../../../inputfileds/form_services/collegemanagement/frontpage.service';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AddtestimonialsComponent } from './addtestimonials/addtestimonials.component';
import { MatMenuModule } from '@angular/material/menu';
import { TestiMonialsDetailDialogComponent } from './testi-monials-detail-dialog/testi-monials-detail-dialog.component';

@Component({
  selector: 'app-testimonials',
  standalone: true,
  imports: [
    MatIcon,
    MatCardModule,
    MatTableModule,
    MatButtonModule,
    MatDialogModule,
    CommonModule,
    MatTooltipModule,
    MatMenuModule,
  ],
  templateUrl: './testimonials.component.html',
  styleUrl: './testimonials.component.scss',
})
export class TestimonialsComponent {
  displayedColumns: string[] = [
    'uploadDate',
    'name',
    'description',
    'images',
    'actions',
  ];
  testimonialsData: any = [];
  editId: string | null = null;
  videoControls = true;

  constructor(
    private ApiService: ApiservicesService,
    private dialog: MatDialog,
    private bannersService: FrontpageService,
    private apiservice: ApiservicesService
  ) {}

  ngOnInit(): void {
    this.getTestimonialsData();
  }
  
  openUploadDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = [
      'dialog-bottom-enter',
      'dialog-bottom-leave',
      'animate__animated',
    ];
    dialogConfig.minWidth = '50vw';
    dialogConfig.minHeight = '65vh';
    dialogConfig.maxHeight = '65vh';

    const dialogRef = this.dialog.open(AddtestimonialsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getTestimonialsData();
      }
    });
  }
  editItem(element: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = [
      'dialog-bottom-enter',
      'dialog-bottom-leave',
      'animate__animated',
    ];
    dialogConfig.minWidth = '50vw';
    dialogConfig.minHeight = '65vh';
    dialogConfig.data = {
      heading: element.name,
      description: element.description,
      media_url: element.media_url,
      id: element.id,
    };

    const dialogRef = this.dialog.open(AddtestimonialsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getTestimonialsData();
      }
    });
  }

  deleteItem(testimonialId: number): void {
    const confirmDelete = confirm(
      'Are you sure you want to delete this banner?'
    );
    if (confirmDelete) {
      this.apiservice.deleteTestimonials(testimonialId).subscribe(
        (res: any) => {
          if (res.code === 200 && res.status === true) {
            console.log('Banner deleted successfully:', res.message);
            this.getTestimonialsData();
          } else {
            console.error(
              'Error deleting banner:',
              res.message || 'Unknown error'
            );
          }
        },
        (error) => {
          console.error('API Error during delete:', error);
        }
      );
    }
  }

  viewItem(element?: any,  videoPlayer?: HTMLVideoElement): void {
    if ( videoPlayer ) {
      videoPlayer.pause();
    }
            const dialogConfig = this.configureDialog({
              data: {
                testimonials: element, // Pass the selected banner data
                editCallback: (testimonials: any) => this.editItem(testimonials),
                deleteCallback: (id: number) => this.deleteItem(id),
              },
              minHeight: '40vh',
              maxHeight: '80vh',
            });
          
            const dialogRef = this.dialog.open(TestiMonialsDetailDialogComponent, dialogConfig);
          
            dialogRef.afterClosed().subscribe(() => {
              console.log('View dialog closed');
            });
          }

  getTestimonialsData() {
    this.ApiService.getTestimonialslist().subscribe(
      (data: any) => {
        this.testimonialsData = data.rows;
      },
      (error) => {
        console.error('Error fetching banners data:', error);
      }
    );
  }
  private configureDialog(config: Partial<MatDialogConfig>): MatDialogConfig {
    return {
      disableClose: true,
      panelClass: [
        'dialog-bottom-enter',
        'dialog-bottom-leave',
        'animate__animated',
      ],
      maxWidth: '50vw',
      ...config,
    };
  }
}
