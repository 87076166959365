import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AddactivitydialogComponent } from '../../../../components/websitemenagement/Activities/addactivitydialog/addactivitydialog.component';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';

interface EventData {
  image_urls: string[];
  heading: string;
  description: string;
  date: string;
  other_pages: string[];
}

@Component({
  selector: 'app-activity-overview',
  templateUrl: './activity-overview-component.component.html',
  styleUrls: ['./activity-overview-component.component.scss'],
  standalone: true,
  imports: [MatCardModule, MatTableModule, CommonModule, MatIconModule, MatButtonModule, MatMenuModule],
})
export class ActivityOverviewComponent implements OnInit {
  displayedColumns: string[] = ['id', 'heading', 'description', 'date', 'images', 'other_pages', 'actions'];
  eventList: (EventData & { id: number })[] = [];
  pageId!: string;
  imagePath = this.apiServices.environmentPath;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private apiServices: ApiservicesService
  ) {}

  ngOnInit(): void {
    this.pageId = this.route.snapshot.paramMap.get('id') || '';
    this.ActivitiesEventList();
  }

  openAddActivityDialog(): void {
    const dialogRef = this.dialog.open(AddactivitydialogComponent, {
      minHeight: '70vh',
      maxHeight: '70vh',
      maxWidth: '70vw',
      minWidth: '70vw',
      data: { pageId: this.pageId } 
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.ActivitiesEventList()
      if (result) {
      }
    });
  }

  editItem(element: any): void {
    const dialogConfig = this.dialog.open(AddactivitydialogComponent, {
      minHeight: '65vh',
      maxHeight: '65vh',
      maxWidth: '70vw',
      minWidth: '70vw',
      data: { 
        pageId: this.pageId,  // Include the pageId if needed
        activityData: element // Pass the existing activity data
      }
    });
    dialogConfig.afterClosed().subscribe((result: any) => {
      if (result) {
        this.ActivitiesEventList();  // Refresh the activity list after edit
      }
    });
  }
  

  ActivitiesEventList(): void {
    if (!this.pageId) {
      console.error('Page ID is missing.');
      alert('Failed to load activities. Invalid page ID.');
      return;
    }

    this.apiServices.getActivitiesEventData(this.pageId).subscribe(
      (data: any) => {
        if (data && Array.isArray(data)) {
          this.eventList = data.map((item, index) => ({
            id: index + 1,
            heading: item.heading || 'N/A',
            description: item.page_name || 'N/A',
            date: item.date || 'N/A',
            image_urls: item.image_urls || [],
            other_pages: item.other_pages || [],
          }));
        } else {
          this.eventList = [];
        }
      },
      (error) => {
        console.error('Error fetching activities data:', error);
        alert('Failed to load activities. Please try again later.');
      }
    );
  }

  goBack(): void {
    this.router.navigate(['/activities']);
  }
}
