<section class="container">
  <h2 class="text-start m-4">
    Add {{ data?.content_type === "mission" ? "Mission" : "Vision" }}
  </h2>
  <mat-divider></mat-divider>
  <div class="d-flex justify-content-between align-items-start mt-4">
    <form [formGroup]="missionVissionForm" class="flex-grow-1 me-4">
      <div *ngFor="let question of questions" class="col-10 mb-3">
        <div class="form-group">
          <app-question
            [question]="question"
            [form]="missionVissionForm"
            class="mission-vision-textarea"
          ></app-question>
        </div>
      </div>
    </form>
    <div class="selected-image-container">
      <div class="col-md-12 d-flex flex-column">
        <input
          #fileInput
          hidden
          type="file"
          color="primary"
          (change)="imageInputChange($event)"
        />
        <div *ngIf="!selectedImageUrl" class="image-preview">
          Click here to upload an image
        </div>
        <!-- <img
            *ngIf="!selectedImageUrl"
            src="assets/images/Clip path group.png"
            alt="Placeholder"
            class="placeholder-image"
            (click)="fileInput.click()"
          /> -->
          <img
          *ngIf="!selectedImageUrl && data?.image_url"
          [src]="data.image_url ? 'https://dbraeiassetmanagement.blob.core.windows.net/dbradcdev/' + missionVissionForm.value.image_url : 'assets/images/Clip path group.png'"
          alt="Existing Image"
          class="selected-image"
        />        
        <div
          *ngIf="selectedImageUrl"
          class="image-preview"
          (click)="fileInput.click()"
        >
          <img
            [src]="selectedImageUrl"
            alt="Selected Image"
            class="selected-image"
          />
        </div>
      </div>
    </div>
  </div>
  <mat-dialog-actions align="center" class="bg-white bottom-0 end-0">
    <div class="d-flex gap-2">
      <button
        mat-raised-button
        (click)="closeDialog(false)"
        class="px-5 cancel_button"
        color="warn"
      >
        Cancel
      </button>
      <button
        mat-raised-button
        color="primary"
        class="px-5 submit_button"
        type="submit"
        (click)="onEdit()"
        [disabled]="!missionVissionForm.valid"
      >
        Update {{ data?.content_type === "mission" ? "Mission" : "Vision" }}
      </button>
    </div>
  </mat-dialog-actions>
</section>
