<section class="mx-3 h-100">
    <app-toast></app-toast>
    <div class="header justify-content-between align-items-center d-flex">
        <div>
            <h2 class="m-0">Add Time Table</h2>
        </div>
        <div>
            <button mat-raised-button class="m-2 submit_button" color="primary" type="submit" (click)="onsubmit()" [disabled]="timetableform.invalid">
                <!-- (click)="onSave()"
        [disabled]="!isFormValid() || isTimeSlotsEmpty()" --> 
                Save
            </button>
            <button mat-raised-button color="primary" type="button" class="cancel_button" (click)="onExit()">
                Exit
            </button>
        </div>
    </div>
    <div class="bg-white p-2 my-2">
        <form [formGroup]="timeslotform">
            <div class="row mt-4">
                <div class="col-lg-10 col-md-12 col-sm-12 col-xl-10 col-xxl-10">
                    <div class="row">
                <div *ngFor="let question of questions"
                    class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2 col-xxl-2">
                    <app-question [question]="question" [form]="timeslotform"></app-question>
                </div>
            </div>
            </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 col-xxl-2 d-flex align-items-center justify-content-end">
                <button mat-raised-button color="primary"  class="submit_button" [disabled]="timeslotform.invalid" (click)="gettimetabledata()">
                    submit
                  </button>
                </div>

            </div>
        </form>
        <div>
        </div>
        <div class="my-2">
            <mat-divider></mat-divider>
        </div>
        <!-- <form [formGroup]="addTimeslotform">
            <div *ngFor="let Addquestion of timeSlots"
                class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
                {{Addquestion}}
            </div>
        </form> -->
        <!-- <ul class="p-0 mt-4">
        <form [formGroup]="timetableform" (submit)="onsubmit()">
           <li class="d-flex" *ngFor="let Addquestion of timeSlots; let i = index ">
            <div class="me-2">
                <label class="form-label">Time</label>
                <input class="form-control" [disabled]="true" [value]="Addquestion.start_time + '-' + Addquestion.end_time" [formControlName]="'shift_id' + i">
            </div>
            <div class="me-2">
                <label class="form-label">Day </label>
                <input class="form-control" [disabled]="true" [value]="Addquestion.start_day" [formControlName]="'Day' + i">
            </div>
            <div class="me-2">
                <label class="form-label">Subject</label>
                <select class="form-select" (change)="onchangesubjects($event,i)" [formControlName]="'subject_id' + i">
                <option selected disabled [value]="0">Choose Options</option>
                <option *ngFor="let opt of timetable_subjects" class="py-4" [value]="opt | json" >{{opt.subject_name}}</option>
            </select>
            </div>
            <div class="me-2">
                <label class="form-label">Employee </label>
                <select class="form-select" [formControlName]="'employee_id'+ i" >
                <option selected disabled [value]="0">Choose Options</option>
                <option *ngFor="let opt of employeedropdata[i]" class="py-4" [value]="opt.id">{{opt.name}}</option>
                </select>
            </div>
            <div class="d-flex align-items-end">
                <button mat-raised-button class="me-2" color="primary" type="button" (click)="onExit()">
                    Edit
                </button>

                <button mat-raised-button  color="primary" type="submit">
                    (click)="onSave()"
            [disabled]="!isFormValid() || isTimeSlotsEmpty()"
                    Save
                </button>
            </div>
           </li> 
           <div>
            <button mat-raised-button class="me-2" color="primary" type="submit">
                Submit
            </button>
           </div>
        </form>
        </ul> -->
        <!-- <mat-divider></mat-divider>
      <form [formGroup]="addTimeslotform" (ngSubmit)="addTimeSlot()">
        <div class="row mt-3 container">
          <div
            *ngFor="let Addquestion of AddSlotQuestions"
            class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3"
          >
            <app-question
              [question]="Addquestion"
              [form]="addTimeslotform"
            ></app-question>
          </div>
        </div>
        <button *ngIf="!editMode" mat-raised-button color="primary" type="submit">
          + Add Time Slot
        </button>
        <button *ngIf="editMode" mat-raised-button color="primary" type="submit">
          Update Time Slot
        </button>
      </form> -->
        <div class="mt-3">
            <form [formGroup]="timetableform" (submit)="onsubmit()">
                <div formArrayName="rows">
                    <div class="row" *ngFor="let row of rows.controls; let i = index" [formGroupName]="i">
                        <div class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2 col-xxl-2">
                            <label for="shift_id-{{i}}" class="form-label">Time</label>
                            <input id="shift_id-{{i}}" class="form-control" [disabled]="true"
                                [value]="this.rows.at(i).get('start_time')?.value + '-' + this.rows.at(i).get('end_time')?.value">
                        </div>
                        <div class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2 col-xxl-2">
                            <label for="start_day-{{i}}" class="form-label">Day </label>
                            <input id="start_day-{{i}}" class="form-control" [disabled]="true"
                                [value]="this.rows.at(i).get('start_day')?.value">
                        </div>
                        <div class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
                            <label for="subject-{{i}}" class="form-label">Subject:</label>
                            <select id="subject-{{i}}" class="form-select" formControlName="subject"
                                (change)="onSubjectChange($event,i)">
                                <option value="">Select Subject</option>
                                <option *ngFor="let subject of timetable_subjects" [value]="subject | json">
                                    {{subject.subject_name}}</option>
                            </select>
                        </div>
                        <div class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
                            <label for="employee-{{i}}" class="form-label">Employee:</label>
                            <select id="employee-{{i}}" class="form-select" formControlName="employee">
                                <option value="">Select Employee</option>
                                <option *ngFor="let lecturer of employeedropdata[i] || []" [value]="lecturer | json">
                                    {{lecturer.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <!-- <div>
            <button mat-raised-button class="me-2" color="primary" type="submit">
                Submit
            </button>
           </div> -->
            </form>
        </div>
    </div>
</section>