import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { DynamicFormQuestionComponent } from '../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { QuestionControlService } from '../../../inputfileds/form_services/question-control.service';
import { LeavesassignedService } from '../../../inputfileds/form_services/leavesassigned/leavesassigned.service';
import { Inputfiledsbase } from '../../../inputfileds/models/inputfiledsbase';
import { Observable } from 'rxjs';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SelectionModel } from '@angular/cdk/collections';
import { DropdownservicesService } from '../../../Services/Apis/Dropdownservices/dropdownservices.service';
import { PermissionService } from '../../../components/Authentication/services/permission.service';

export interface AssignLeaveView {
  index: number,
  id: number,
  organization_id: number,
  employee_id: number,
  leave_type: string,
  number_of_allowed_leaves: number,
  department_code: string,
  number_of_remaining_leaves: number,
  allowed_carry_forward: boolean,
}

@Component({
  selector: 'app-leavesassigned',
  standalone: true,
  providers: [QuestionControlService, LeavesassignedService, ApiservicesService, DropdownservicesService],
  imports: [CommonModule, DynamicFormQuestionComponent, MatCheckboxModule, MatTableModule, ReactiveFormsModule, MatButtonModule],
  templateUrl: './leavesassigned.component.html',
  styleUrl: './leavesassigned.component.scss'
})
export class LeavesassignedComponent {
  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  form!: FormGroup;
  payLoad: any;
  datesend: any;
  getAsignstudentData: AssignLeaveView[] = [];
  displayedColumns: string[] = ['select', 'Employee_Name', 'leave_type', 'number_of_allowed_leaves', 'number_of_remaining_leaves',];
  dataSource = new MatTableDataSource<AssignLeaveView>(this.getAsignstudentData); actiondata: any;
  ;
  selection = new SelectionModel<AssignLeaveView>(true, []);
  showaddflag: boolean = false;
  leaveTypeDropData: any[] = [];
  AsignleavesDataData: any[] = [];

  constructor(
    private qcs: QuestionControlService,
    service: LeavesassignedService,
    private apiService: ApiservicesService,
    private dropdownservice: DropdownservicesService,
    private permissionService: PermissionService,
  ) {
    this.actiondata = this.permissionService.getactiondata();
    if (this.actiondata.view === false) {
      this.displayedColumns.splice(this.displayedColumns.indexOf('actions'), 1);
    }
    this.questions$ = service.getassignleaveform();
    this.questions$.subscribe(q => this.questions = q);
    this.loadDropdownData('ASIGNED_LEAVES', this.leaveTypeDropData);
    this.getemmployeelist()
  }

  private loadDropdownData(code: string, dataArray: any[]) {
    let observable: Observable<any>;
    observable = this.dropdownservice.getassignleavedrop();

    observable.subscribe((res) => {
      for (let i = 0; i < res.length; i++) {
        let lookupname;
        if (code === 'ASIGNED_LEAVES') {
          lookupname = res[i].maximum_allowed_leaves;
        }
        dataArray.push({
          id: res[i].id,
          key: lookupname,
          value: res[i].id,
        });
      }
    });
  }
  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.questions as Inputfiledsbase<string>[]);
  }

  public ngAfterViewChecked() {
    if (this.form.controls["leave_type_id"].valid) {
      const selectedLeaveTypeId = this.form.controls["leave_type_id"].value;
      const selectedLeaveType = this.leaveTypeDropData.find(item => item.id === selectedLeaveTypeId);
      if (selectedLeaveType) {
        this.form.controls['number_of_allowed_leaves']?.patchValue(selectedLeaveType.key);
      }
    }
  }

  showadd() {
    this.showaddflag = !this.showaddflag;
  }
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  checkboxLabel(row?: AssignLeaveView): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.index + 1}`;
  }

  getemmployeelist() {
    this.apiService.getassignleavelist().subscribe((res) => {
      this.AsignleavesDataData = res
      this.dataSource = new MatTableDataSource<AssignLeaveView>(res)
    })
  }

  onSubmit() {
    this.payLoad = this.form.getRawValue();
    this.dropdownservice.getassignleavedrop().subscribe(data => {
      const filteredLeaveType = data.find((dropfilter: { id: any; }) => dropfilter.id === this.form.value.leave_type_id);
      if (filteredLeaveType) {
        this.payLoad = { ...this.payLoad, leave_type: filteredLeaveType.name };
        this.createAPi(this.payLoad);
      } else {
        console.error('Leave type not found for selected leave_type_id:', this.form.value.leave_type_id);
      }
    });
  }


  createAPi(event: any) {
    this.apiService.createassignedleaves(event).subscribe((res: any) => {
      if (res.code === 201 && res.status === true) {
        this.form.reset();
      }
    })
  }
}
