import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';
import { MatDialog, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { FrontpageService } from '../../../../inputfileds/form_services/collegemanagement/frontpage.service';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AddmissionvisionComponent } from './addmissionvision/addmissionvision.component';

@Component({
  selector: 'app-vision-mission',
  standalone: true,
  imports: [
    MatIcon,
    MatCardModule,
    MatTableModule,
    MatButtonModule,
    MatDialogModule,
    CommonModule,
    MatTooltipModule,
  ],
  templateUrl: './vision-mission.component.html',
  styleUrl: './vision-mission.component.scss',
})
export class VisionMissionComponent {
  displayedColumns: string[] = [
    'uploadDate',
    'content_type',
    'info',
    'images',
    'actions',
  ];
  VisionMissionData: any = [];

  constructor(
    private ApiService: ApiservicesService,
    private dialog: MatDialog,
    private bannersService: FrontpageService
  ) {}

  ngOnInit(): void {
    this.getMissionVisionData();
  }

  openUploadDialog(contentType: 'missions' | 'visions', rowData: any = null): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = ['dialog-bottom-enter', 'dialog-bottom-leave', 'animate__animated'];
    dialogConfig.minWidth = '50vw';
    dialogConfig.minHeight = '65vh';
    dialogConfig.maxHeight = '65vh';

    // Pass content type and row data for editing
    dialogConfig.data = { content_type: contentType, rowData };

    const dialogRef = this.dialog.open(AddmissionvisionComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getMissionVisionData();
      }
    });
  }

  getMissionVisionData() {
    this.ApiService.getMissionVissionlist().subscribe(
      (data: any) => {
        this.VisionMissionData = data.rows;
      },
      (error) => {
        console.error('Error fetching vision/mission data:', error);
      }
    );
  }
}
