<div class="homepage-container">
  <h2>Home Page</h2>
  
  <div class="content-wrapper">
      <!-- Sidebar -->
      <div class="sidebar">
          <mat-list>
              <mat-list-item
                  *ngFor="let item of componentsList"
                  [class.active]="item.value === selectedComponent"
                  [style]="selectedComponent ? '' : 'color: #FFFFF !important'"
                  (click)="onSelectComponent(item)"
              >
                  {{ item.label }}
              </mat-list-item>
          </mat-list>
      </div>

      <!-- Dynamic Content -->
      <div class="content" [ngSwitch]="selectedComponent">
          <app-banners *ngSwitchCase="'Banners'"></app-banners>
          <app-notice-board *ngSwitchCase="'Notice Board'"></app-notice-board>
          <app-vision-mission *ngSwitchCase="'Vision & Mission'"></app-vision-mission>
          <app-testimonials *ngSwitchCase="'Testimonials'"></app-testimonials>
      </div>
  </div>
</div>
