import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { DynamicFormQuestionComponent } from '../../../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { QuestionControlService } from '../../../../../inputfileds/form_services/question-control.service';
import { ApiservicesService } from '../../../../../Services/Apis/apiservices.service';
import { DropdownservicesService } from '../../../../../Services/Apis/Dropdownservices/dropdownservices.service';
import { Inputfiledsbase } from '../../../../../inputfileds/models/inputfiledsbase';
import { Observable } from 'rxjs';
import { FrontpageService } from '../../../../../inputfileds/form_services/collegemanagement/frontpage.service';

export interface missionVissionModel {
  description: string;
  image_url: File | null;
}

@Component({
  selector: 'app-addmissionvision',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatDividerModule,
    DynamicFormQuestionComponent,
    MatIconModule,
  ],
  templateUrl: './addmissionvision.component.html',
  styleUrl: './addmissionvision.component.scss',
  providers: [
    QuestionControlService,
    ApiservicesService,
    DropdownservicesService,
  ],
})
export class AddmissionvisionComponent {
  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  missionVissionForm!: FormGroup;
  datesend!: missionVissionModel;
  missionVisiondata: any = {};
  imageuploaddata: any = {};
  url: string | ArrayBuffer | null | undefined;
  editflag: boolean = false;
  fileName: string | null = null;
  selectedImageUrl: string | ArrayBuffer | null = null;
  file!: File;
  isButtonLinkEnabled: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<AddmissionvisionComponent>,
    private dropdownservice: DropdownservicesService,
    Noticesservices: FrontpageService,
    private qcs: QuestionControlService,
    private apiservice: ApiservicesService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    {
      this.questions$ = Noticesservices.getMissionVissionForm();
      this.questions$.subscribe((q) => (this.questions = q));
      console.log('missionVissionForm======>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>',data);
      this.missionVissionForm = this.fb.group({
        content_type: [data.content_type || ''], // Default to the passed content_type
        description: [data.rowData?.content || ''], // Populate the description field
        image_url: [data.rowData?.image_url || null] // Populate the image URL field
      });    
      if (data.rowData?.image_url) {
        this.selectedImageUrl = `https://dbraeiassetmanagement.blob.core.windows.net/dbradcdev/${data.rowData.image_url}` || null; // Set the image preview
      }
    }
  }
  ngOnInit(): void {}
  onSave(): void {
    const formData = this.missionVissionForm.value;
    this.dialogRef.close(formData);
  }

  onEdit() {
    if (this.missionVissionForm.invalid) {
      console.error('Form is invalid. Please fix the errors and try again.');
      return;
    }
  
    const organizationData = sessionStorage.getItem('user');
    let organizationId: number | null = null;
  
    if (organizationData) {
      try {
        const parsedData = JSON.parse(organizationData);
        organizationId = parsedData.data?.organization_id || null;
      } catch (error) {
        console.error('Error parsing organization data:', error);
      }
    }
  
    if (!organizationId) {
      console.error('Organization ID not found. Cannot proceed.');
      return;
    }
  
    const formData = new FormData();
    console.log('Editing mission/vision...', this.data);
    const contentType = this.data?.content_type || 'visions';
    formData.append('content', this.missionVissionForm.value.description.substring(0, 500));
    formData.append('organization', organizationId.toString());
  
    // Check if a new file is uploaded
    if (this.file) {
      formData.append('image_url', this.file);
    } else if (this.selectedImageUrl) {
      // If no file selected but an image URL is available (existing image), use that
      formData.append('image_url', this.selectedImageUrl as string);
    } else {
      // If no image file is selected and no image URL exists, prompt the user
      console.error('No image selected and no existing image URL found.');
      return;
    }
  
    this.apiservice.updateMissionVission(this.data.rowData.id, formData).subscribe(
      (res: any) => {
        if (res.code === 200 && res.status === true) {
          this.missionVisiondata = res.data;
          console.log('Mission/Vision updated successfully:', res.message);
          this.missionVissionForm.reset();
          this.closeDialog(true);
        } else {
          console.error('Error updating mission/vision:', res.message || 'Unknown error');
        }
      },
      (error) => {
        console.error('API Error during edit:', error);
      }
    );
  }
  

  onSubmit() {
    const organizationData = sessionStorage.getItem('user');
    let organizationId: number | null = null;
  
    if (organizationData) {
      try {
        const parsedData = JSON.parse(organizationData);
        organizationId = parsedData.data?.organization_id || null;
      } catch (error) {
        console.error('Error parsing organization data:', error);
      }
    }
  
    if (!organizationId) {
      console.error('Organization ID not found. Cannot proceed.');
      return;
    }
  
    if (!this.file) {
      console.error('No file selected. Please upload an image.');
      return;
    }
  
    const formData = new FormData();
    console.log('Uploading image...', this.data);
    const contentType = this.data?.content_type || 'visions';
    formData.append('content', this.missionVissionForm.value.description);
    formData.append('organization', organizationId.toString());
    formData.append('image_url', this.file);
  
    this.createAPi(formData, contentType);
  }
  
  createAPi(formData: FormData, contentType: string) {  
    this.apiservice.createMissionVission(formData).subscribe(
      (res: any) => {
        if (res.code === 201 && res.status === true) {
          this.missionVisiondata = res.data;
          this.missionVissionForm.reset();
          this.closeDialog(true);
        } else {
          console.error('Error:', res.message || 'Unknown error');
        }
      },
      (error) => {
        console.error('API Error:', error);
      }
    );
  }
  


  imageInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const maxSizeInBytes = this.apiservice.imageMaxSize;
    const maxDymSize = this.apiservice.imageDymSizes;
  
    if (input.files?.length) {
      const file = input.files[0];
      const reader = new FileReader();
  
      if (file.size > maxSizeInBytes) {
        alert(`Image size must not exceed ${maxSizeInBytes / (1024 * 1024)}MB.`);
        input.value = '';
        return;
      }
  
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target?.result as string;
        img.onload = () => {
          if (img.width === maxDymSize.missionVision.width && img.height ===  maxDymSize.missionVision.height) {
            this.selectedImageUrl = img.src;
            this.missionVissionForm.patchValue({ image_url: file });
          } else {
            alert(`Image dimensions must be ${maxDymSize.missionVision.width} x ${maxDymSize.missionVision.height} pixels.`);
            input.value = '';
          }
        };
      };
      reader.readAsDataURL(file);
    } else {
      console.error('No file selected.');
    }
  }
  
  closeDialog(event: any) {
    document
      .getElementsByClassName('animate__animated')[0]
      .classList.remove('animate__fadeIn');
    document
      .getElementsByClassName('animate__animated')[0]
      .classList.add('animate__fadeOut');
    this.dialogRef.close(event);
  }
}
