import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { AddnoticeboardComponent } from './addnoticeboard/addnoticeboard.component';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';
import { FrontpageService } from '../../../../inputfileds/form_services/collegemanagement/frontpage.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { NoticeBoardDetailDialogComponent } from './notice-board-detail-dialog/notice-board-detail-dialog.component';

@Component({
  selector: 'app-notice-board',
  standalone: true,
  imports: [MatIcon, MatCardModule,MatMenuModule, MatTableModule,MatButtonModule, MatDialogModule, CommonModule, MatTooltipModule],
  templateUrl: './notice-board.component.html',
  styleUrl: './notice-board.component.scss'
})
export class NoticeBoardComponent {

  displayedColumns: string[] = ['visible_from','visible_until', 'info', 'status', 'images', 'actions'];
  noticeboardData: any[] = [];
  formattedDates: any;
  editId: string | null = null;
  

  constructor(private apiService: ApiservicesService, private dialog: MatDialog, private bannersService: FrontpageService, private apiservice: ApiservicesService) {

  }
  imagePath = this.apiservice.environmentPath;

    ngOnInit(): void {
      this.getNoticeBoardData(); 
    }

    openUploadDialog(): void {
      const dialogConfig = this.configureDialog({
        data: null,
        minHeight: '65vh',
        maxHeight: '65vh',
      });
  
      const dialogRef = this.dialog.open(AddnoticeboardComponent, dialogConfig);
  
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          this.getNoticeBoardData();
        }
      });
    }
  
    editItem(element: any): void {
      const dialogConfig = this.configureDialog({
        data: element,
        minHeight: '65vh',
        maxHeight: '65vh',
      });
  
      const dialogRef = this.dialog.open(AddnoticeboardComponent, dialogConfig);
  
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          this.getNoticeBoardData();
        }
      });
    }

        viewItem(element: any): void {
          const dialogConfig = this.configureDialog({
            data: {
              notice: element, // Pass the selected banner data
              editCallback: (notice: any) => this.editItem(notice),
              deleteCallback: (id: number) => this.deleteApi(id),
            },
            minHeight: '40vh',
            maxHeight: '80vh',
          });
        
          const dialogRef = this.dialog.open(NoticeBoardDetailDialogComponent, dialogConfig);
        
          dialogRef.afterClosed().subscribe(() => {
            console.log('View dialog closed');
          });
        }

    deleteApi(id: number) {
      const confirmDelete = confirm('Are you sure you want to delete this banner?');
      if (confirmDelete) {
        this.apiservice.deleteNoticeBoard(id).subscribe(
          (res: any) => {
            if (res.code === 200 && res.status === true) {
              console.log('Banner deleted successfully:', res.message);
              this.getNoticeBoardData();
            } else {
              console.error('Error deleting banner:', res.message || 'Unknown error');
            }
          },
          (error) => {
            console.error('API Error during delete:', error);
          }
        );
      }
    }
  
    getNoticeBoardData(): void {
      this.apiService.getNoticeBoardlist().subscribe(
        (data: any) => {
          this.noticeboardData = data.rows || [];
        },
        (error) => {
          console.error('Error fetching notice board data:', error);
        }
      );
    }
  
    private configureDialog(config: Partial<MatDialogConfig>): MatDialogConfig {
      return {
        disableClose: true,
        panelClass: ['dialog-bottom-enter', 'dialog-bottom-leave', 'animate__animated'],
        maxWidth: '50vw',
        ...config,
      };
    }
  }