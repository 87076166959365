import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { Observable, catchError } from "rxjs";
import { DynamicFormQuestionComponent } from "../../../../inputfileds/dynamic-form-question/dynamic-form-question.component";
import { QuestionControlService } from "../../../../inputfileds/form_services/question-control.service";
import { CreatepageserviceService } from "../../../../inputfileds/form_services/web_activities/createpageservice.service";
import { Inputfiledsbase } from "../../../../inputfileds/models/inputfiledsbase";
import { ApiservicesService } from "../../../../Services/Apis/apiservices.service";
import { DropdownservicesService } from "../../../../Services/Apis/Dropdownservices/dropdownservices.service";

@Component({
  selector: "app-create-page-dialog",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatDividerModule,
    DynamicFormQuestionComponent,
    MatIconModule,
  ],
  templateUrl: "./createpagedialogcomponent.component.html",
  styleUrls: ["./createpagedialogcomponent.component.scss"],
  providers: [
    QuestionControlService,
    ApiservicesService,
    DropdownservicesService,
  ],
})
export class CreatePageDialogComponent {
  questions: Inputfiledsbase<string>[] = [];
  questions$: Observable<Inputfiledsbase<string>[]>;
  pageForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private createpageServices: CreatepageserviceService,
    private dialogRef: MatDialogRef<CreatePageDialogComponent>,
    private apiServices: ApiservicesService
  ) {
    // Initialize the form
    this.pageForm = this.fb.group({
      page_name: ["", Validators.required],
    });

    // Load dynamic questions
    this.questions$ = this.createpageServices.getCreatePage();

    this.questions$
      .pipe(
        catchError((err) => {
          console.error("Error loading questions:", err);
          return [];
        })
      )
      .subscribe((questions) => {
        this.questions = questions || [];
        this.addDynamicControls(questions);
      });
  }

  private addDynamicControls(questions: Inputfiledsbase<string>[]): void {
    questions.forEach((question) => {
      console.log(`Adding control for: ${question.key}`);
      this.pageForm.addControl(
        question.key,
        this.fb.control(question.value || "", question.required ? Validators.required : [])
      );      
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  trackByQuestionKey(index: number, question: Inputfiledsbase<string>): string {
    return question.key;
  }

  onSubmit(): void {
    const organizationData = sessionStorage.getItem('user');
    let organizationId: number | null = null;

    if (organizationData) {
      try {
        const parsedData = JSON.parse(organizationData);
        if (parsedData.data && parsedData.data.organization_id) {
          organizationId = parsedData.data.organization_id;
        } else {
          console.error('Parsed data does not contain expected structure');
        }
      } catch (error) {
        console.error('Error parsing data from sessionStorage:', error);
      }
    }

    if (organizationId === null) {
      console.error('Organization ID not found');
      return;
    }
    const pageData = {
      organization_id: organizationId,
      page_name: this.pageForm.value.page_name,
      module_id: "3",
      sub_module_name: "ACTIVITIES",
      sub_organization_id: 1,
    };

    this.createPage(pageData);
  }

  private createPage(pageData: any): void {
    this.apiServices.createActivitiesPage(pageData).subscribe({
      next: (response) => {
        this.dialogRef.close(response);
      },
      error: (err) => {
        console.error("Error creating page:", err);
      },
    });
  }
}
