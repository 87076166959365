<section class="container">
  <h2 class="text-start m-4">Add Notice</h2>
  <mat-divider></mat-divider>
  <form [formGroup]="noticeForm" class="pt-3">
    <div class="row">
      <div *ngFor="let question of questions" class="col-6 mb-3">
        <app-question [question]="question" [form]="noticeForm"></app-question>
      </div>
      <div class="form-group d-flex align-items-center mb-3">
        <mat-slide-toggle color="primary" (change)="onToggleChange($event)">
          Need Button?
        </mat-slide-toggle>
      </div>
      <div class="form-group col-12 mb-3" *ngIf="isButtonLinkEnabled">
        <label for="button_text">Button Text</label>
        <input id="button_text" type="text" matInput formControlName="button_text" placeholder="Enter button text" class="form-control" />
    </div>
    <div class="form-group col-12 mb-3" *ngIf="isButtonLinkEnabled">
        <label for="button_link">Button Link</label>
        <input id="button_link" type="text" matInput formControlName="button_link" placeholder="Enter button link" class="form-control" />
    </div>
    
      <div class="col-md-4 col-sm-6 d-flex flex-column align-items-start">
        <input
          #fileInput
          hidden
          type="file"
          (change)="imageInputChange($event)"
        />
        <button mat-raised-button color="primary" class="image_choose_button" (click)="fileInput.click()">
          Choose Image
        </button>
        <p *ngIf="fileName" class="mt-2">Selected file: {{ fileName }}</p>
        <p *ngIf="!fileName" class="text-start mt-1">
          <span class="text-danger">*</span>Upload Banner Image
        </p>
      </div>
      <div class="col-md-2 col-sm-6 d-flex align-items-center mt-3 mt-md-0">
        <img
          *ngIf="selectedImageUrl"
          [src]="selectedImageUrl"
          alt="Selected Image"
          style="width: 4.5rem; height: 4.5rem; cursor: pointer"
        />
      </div>
    </div>
    <!-- Dialog Actions -->
    <mat-dialog-actions align="center" class="bg-white bottom-0 end-0">
      <div class="d-flex gap-2">
        <button mat-raised-button (click)="closeDialog(false)" class="cancel_button" color="warn">
          Cancel
        </button>
        <button mat-raised-button color="primary" class="submit_button" type="submit"  [disabled]="!noticeForm.valid" (click)="onSubmit()">
          Submit
        </button>
      </div>
    </mat-dialog-actions>
    
  </form>
</section>
