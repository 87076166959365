import { Injectable } from '@angular/core';
import { Inputfiledsbase } from '../../models/inputfiledsbase';
import { TextboxQuestion } from '../../question-textbox';
import { of, Observable } from 'rxjs';
import { TextareaQuestion } from '../../question-textarea';
import { DatepickerQuestion } from '../../question-datepicker';
import { MultiSelectQuestion } from '../../question-multiselect';
import { DropdownQuestion } from '../../question-dropdown';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { DropdownservicesService } from '../../../Services/Apis/Dropdownservices/dropdownservices.service';

@Injectable({
  providedIn: 'root',
})
export class CreatepageserviceService {
  webpagelist: any = [];
  constructor(private dropdownservice: DropdownservicesService) {
    this.dropdownservice.getwebpagedrop().subscribe((webpage:any) => {
      console.log("webpage", webpage)
      for (let i = 0; i <= webpage?.length; i++) {
        this.webpagelist?.push({ id: webpage[i].id, key: webpage[i].page_name, value: webpage[i].id })
      }
    });
  }    

  getCreatePage(): Observable<Inputfiledsbase<string>[]> {
    const questions: Inputfiledsbase<string>[] = [
      new TextboxQuestion({
        key: 'page_name',
        label: 'Page Name',
        required: true,
        order: 1,
      })
    ];
    return of(questions);
  }

  getCreateEvents(): Observable<Inputfiledsbase<string>[]> {
    const questions: Inputfiledsbase<string>[] = [
      new TextboxQuestion({
        key: 'heading',
        label: 'Heading ',
        required: true,
        order: 1,
      }),
      new TextareaQuestion({
        key: 'description',
        label: 'Description',
        required: true,
        order: 2,
      }),
      new DatepickerQuestion({
        key: 'date',
        label: 'Event Date',
        required: true,
        order: 3,
      }),
      new MultiSelectQuestion({
        key: 'web_page_id',
        label: 'Also Add other pages',
        required: true,
        options: this.webpagelist,
        order: 3,
      }),
    ];
    return of(questions);
  }

}
