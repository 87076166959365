import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatDialog } from '@angular/material/dialog';
import { CreatePageDialogComponent } from './createpagedialogcomponent/createpagedialogcomponent.component';
import { Router } from '@angular/router';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';


@Component({
  selector: 'app-activitiespageview',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatCardModule,
    MatDatepickerModule,
    MatInputModule
  ],
  templateUrl: './activitiespageview.component.html',
  styleUrls: ['./activitiespageview.component.scss'],
})
export class ActivitiesPageViewComponent implements OnInit {
  displayedColumns: string[] = ['id', 'page_name', 'createdAt', 'actions'];
  activitiesData = [];
  constructor(private dialog: MatDialog, private router: Router,private apiServices: ApiservicesService) {}

  ngOnInit(): void {
    this.getActivitiesData()
  }

  openCreatePageDialog(): void {
    const dialogRef = this.dialog.open(CreatePageDialogComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getActivitiesData()
      if (result) {
        const newActivity = {
          id: this.activitiesData.length + 1,
          sub_menu: result,
          status: 'active',
          image_url: '',
          data_json: []
        };
        this.activitiesData;
      }
    });
  }
  navigateToActivityPage(id: number, data: any): void {
    this.router.navigate([`/activities/${id}`], { state: { activityData: data } });
  }

  
  getActivitiesData() {
    this.apiServices.getActivitiesPagelist().subscribe(
      (data: any) => {
        this.activitiesData = data.rows;
      },
      (error) => {
        console.error('Error fetching activities data:', error);
        alert('Failed to load activities. Please try again later.');
      }
    );
  }
  

}
