<section>
<div class="container">
  <div class="left-section">
    <h2 mat-dialog-title>Create Events</h2>
    <mat-dialog-content>
      <form [formGroup]="pagecreationForm" class="event-form">
        <div class="form-group">
          <div *ngFor="let question of questions">
            <app-question [question]="question" [form]="pagecreationForm"></app-question>
          </div>
        </div>
      </form>
    </mat-dialog-content>
  </div>

  <div class="right-section">
    <div class="upload-container">
      <label for="image-upload" class="upload-label">Upload Images</label>
      <div class="upload-dashed-border" (click)="fileInput.click()" *ngIf="!images.length && !previewUrl.length">
        <span>Click to upload</span>
      </div>
      <input
        id="image-upload"
        type="file"
        (change)="onImageUpload($event)"
        multiple
        class="upload-input"
        #fileInput
        style="display: none"
      />
      
      <div *ngIf="images.length || previewUrl.length" class="carousel-container">
        <button class="carousel-nav prev" (click)="prevSlide()" aria-label="Previous Slide">&#10094;</button>
        <div class="carousel-slide" *ngFor="let url of previewUrl; let i = index" [class.active]="i === currentSlide">
          <img [src]="url" alt="Uploaded image" class="carousel-image" />
          <button class="delete-image" *ngIf="images[i]" (click)="deleteImage(i)" >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        
        <button class="carousel-nav next" (click)="nextSlide()" aria-label="Next Slide">&#10095;</button>
      </div>
      <h5>Number Of Images: {{ images.length + previewUrl.length }}</h5>
      <div *ngIf="previewUrl.length > 0">
        <button mat-raised-button color="primary" class="add-more-button" (click)="fileInput.click()">
          <mat-icon>add</mat-icon>Add More
        </button>
      </div>
    </div>
  </div>
</div>
<mat-dialog-actions class="dialog-actions bottom-actions">
  <button mat-raised-button color="warn" (click)="onCancel()">Cancel</button>
  <button mat-raised-button color="primary" (click)="onSubmit()">{{editflag ? "Update" : "Submit"}}</button>
</mat-dialog-actions>
</section>
