<section class="mx-3">
  <div class="content-header">
    <h1>Home Page - Banners</h1>
    <button
      mat-raised-button
      color="primary"
      (click)="openUploadDialog()"
      class="upload-btn"
    >
      + Upload Banners
    </button>
  </div>
  <!-- <mat-card> -->
  <table mat-table [dataSource]="bannersData" class="mat-elevation-z8">
    <ng-container matColumnDef="fromDate">
      <th mat-header-cell *matHeaderCellDef>From Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.visible_from | date : "dd/MM/yyyy" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="toDate">
      <th mat-header-cell *matHeaderCellDef>To Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.visible_until | date : "dd/MM/yyyy" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="info">
      <th mat-header-cell *matHeaderCellDef>Image Name</th>
      <td mat-cell *matCellDef="let element">{{ element.image_name }}</td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">{{ element.status }}</td>
    </ng-container>
    <ng-container matColumnDef="images">
      <th mat-header-cell *matHeaderCellDef>Images</th>
      <td mat-cell *matCellDef="let element">
        <img
          [src]="this.imagePath + '/' + element.image_url"
          alt="Banner Image"
          class="banner-image"
        />
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <button mat-button class="view_button" (click)="viewItem(element)">
          View
        </button>
        <button
          class="action_button"
          mat-icon-button
          [matMenuTriggerFor]="menu"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            class="update_button"
            *ngIf="editId !== element.id"
            (click)="editItem(element)"
          >
            <mat-icon>edit</mat-icon>
            Edit
          </button>
          <button
            mat-menu-item
            class="delete_button"
            (click)="deleteApi(element.id)"
          >
            <mat-icon>delete</mat-icon>
            Delete
          </button>
        </mat-menu>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <!-- </mat-card> -->
</section>
