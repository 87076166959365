<section class="vision-mission-section">
  <div class="content-header">
    <h1 class="section-title">Home - Vision & Mission</h1>
  </div>
  <ng-container *ngFor="let element of VisionMissionData; let last = last">
    <div class="content-wrapper">
      <div class="image-container">
        <img
          [src]="'https://dbraeiassetmanagement.blob.core.windows.net/dbradcdev/' + element.image_url"
          alt="{{ element.content_type }}"
          matTooltip="{{ element.content_type }}"
          class="content-image"
        />
      </div>
      <div class="content-text">
        <h2 class="content-heading">{{ element.content_type }}</h2>
        <p>{{ element.content | slice: 0:100 }}...</p>
      </div>
      <button mat-icon-button class="edit-icon update_button" (click)="openUploadDialog(element.content_type, element)" aria-label="Edit">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
    <!-- Divider -->
    <hr *ngIf="!last" class="divider" />
  </ng-container>
</section>
