  import { Component } from '@angular/core';
  import { MatTableModule } from '@angular/material/table';
  import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
  import { MatButtonModule } from '@angular/material/button';
  import { MatCardModule } from '@angular/material/card';
  import { MatIconModule } from '@angular/material/icon';
  import { MatListModule } from '@angular/material/list';
  import { BannersComponent } from "../../../components/websitemenagement/Homepage/banners/banners.component";
  import { MatTab, MatTabsModule } from '@angular/material/tabs';
  import { MatDialog } from '@angular/material/dialog';
  import { MatMenuModule } from '@angular/material/menu';
  import { MatPaginatorModule } from '@angular/material/paginator';
  import { NoticeBoardComponent } from "../../../components/websitemenagement/Homepage/notice-board/notice-board.component";
  import { VisionMissionComponent } from "../../../components/websitemenagement/Homepage/vision-mission/vision-mission.component";
  import { TestimonialsComponent } from "../../../components/websitemenagement/Homepage/testimonials/testimonials.component";
  import { CommonModule } from '@angular/common';

  @Component({
    selector: 'app-homepageview',
    standalone: true,
    imports: [
      MatTableModule,
      MatPaginatorModule,
      MatButtonModule,
      MatIconModule,
      MatMenuModule,
      MatCardModule,
      MatSidenavModule,
      MatTabsModule,
      MatListModule,
      BannersComponent,
      NoticeBoardComponent,
      VisionMissionComponent,
      TestimonialsComponent,
      CommonModule
  ],
      providers: [MatDialog],
    templateUrl: './homepageview.component.html',
    styleUrl: './homepageview.component.scss'
  })
  export class HomepageviewComponent {
    displayedColumns: string[] = ['uploadDate', 'noticeInfo', 'status', 'images', 'actions'];
    componentsList = [
      { label: 'Banners', value: 'Banners' },
      { label: 'Notice Board', value: 'Notice Board' },
      { label: 'Vision & Mission', value: 'Vision & Mission' },
      { label: 'Testimonials', value: 'Testimonials' }
    ];
    selectedComponent: string = '';

    ngOnInit(): void {
      this.selectedComponent = this.componentsList[0].value;
    }
    onSelectComponent(item: { label: string; value: string }) {
      this.selectedComponent = item.value;
    }
  }